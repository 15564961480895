import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopOnPageChange = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    const scrollToTop = () => {
      const c = document.documentElement.scrollTop || document.body.scrollTop;
      if (c > 0) {
        window.requestAnimationFrame(scrollToTop);
        window.scrollTo(0, c - c / 8);
      }
    };

    scrollToTop();
  }, [pathname]);

  return null;
};

export default ScrollToTopOnPageChange;
