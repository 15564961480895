
import React from 'react';
import { Link } from 'react-router-dom';
import { ID_LIST, axiosClient } from '../core/config';
import SkeletonPopularCategory from '../components/Skeletons/SkeletonPopularCategory';
interface PageEducation {
    ACF: {
        card_curs: {
            img_curs: string;
            curse_title: string;
            price_curs: string;
            curse_link: string;
        }[];
    };
}

const Education: React.FC = () => {
    const [pageEducation, setPageEducation] = React.useState<PageEducation>();




    React.useEffect(() => {

        const fetchPages = async () => {
            try {
                const { data } = await axiosClient.get(`wp-json/wp/v2/pages/${ID_LIST.education_page_id}`);
                setPageEducation(data);
                console.log(data)


            }

            catch (err) {
                console.log('ошибка запроса обучения ' + err);
            }
        }
        fetchPages();

    }, [])


    return (
        <div className="education">
            <div className="container">
                <div className="page-title">Обучение</div>
                <div className="education-box">
                    {
                        !pageEducation ? [...new Array(4)].map((_, index) => {
                            return (
                                <div key={index} className="education__item">
                                    <SkeletonPopularCategory ></SkeletonPopularCategory>
                                </div>

                            )

                        })
                            :
                            pageEducation.ACF.card_curs.map((obj) => (

                                <Link to={obj.curse_link} key={obj.curse_title} className="education__item">

                                    <img src={obj.img_curs} alt="" />
                                    <div className="education__item-info">
                                        <div className="education__item-title">
                                            {obj.curse_title}
                                        </div>
                                        <div className="flex-box">
                                            <div className={`price ${obj.price_curs === 'Бесплатно' ? 'free' : ''}`}>
                                                {
                                                    obj.price_curs === 'Бесплатно' ? <></>
                                                        :
                                                        <span className="price-text">Цена:</span>
                                                }

                                                <div className="price-value">{obj.price_curs}</div>
                                            </div>

                                            <div className="education__item-btn">
                                                Ознакомиться
                                            </div>
                                        </div>

                                    </div>


                                </Link>
                                
                            ))
                    }


                </div>
            </div>
        </div>
    )
}
export default Education;