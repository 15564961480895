import React from "react";
import { axiosClient } from "../../core/config";
import { Modal } from "antd";
import WindowSuccess from "../ModalWindows/WindowSuccess";
import WindowError from "../ModalWindows/WindowError";
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import './formStyle.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

type TMyform = {
    name: string;
    email: string;
   
}

const FormTomato: React.FC<{ openModal: boolean, closeWindow: any }> = ({ openModal, closeWindow }) => {


    const { register, handleSubmit, formState: { errors } } = useForm<TMyform>({
        defaultValues: {
            name: '',
            email: '',
        }
    });
    const downloadFile = (url: string, fileName: string) => {
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.style.display = 'none';
        document.body.appendChild(a); 
        a.click();
        document.body.removeChild(a); 
         console.log('ok')
      }
      
   
    const submit: SubmitHandler<TMyform> = async (data, event) => {
        try {
            downloadFile("https://dev.yakovogorodnik.ru/files/tomato.pdf", "да"); 
            console.log('Отправилось успешно');

            event?.target.reset();
        } catch (error) {

            console.error('Ошибка при отправке', error);

        }
    };
    const error: SubmitErrorHandler<TMyform> = (error) => {
        console.log('ошибка при отправлени', error)

    }


    return (
        <Modal className="windowModal" open={openModal} closeIcon={<FontAwesomeIcon icon={faXmark} />} onCancel={closeWindow} footer={null}>
           
            <div className="course-form">

                <form onSubmit={handleSubmit(submit, error)}>
                    <div className="course-form__title">
                    ПОСОБИЕ ПО ВЫРАЩИВАЮ ТОМАТОВ
                    </div>

                    <div className="text">Заполните форму и нажмите скачать</div>



                    <label className={errors.name ? 'error' : ''}> 
                                <span>Имя</span>
                                <input {...register('name', { 
                                    required: true,
                                    pattern: /^[A-Za-zА-Яа-яЁё\s]+$/i,
                                })} placeholder='Введите ваше имя...' type="text" />
                                {errors.name && errors.name.type === 'required' && (
                                    <p>Поле обязательно для заполнения</p>
                                )}
                                {errors.name && errors.name.type === 'pattern' && (
                                    <p>Имя может содержать только буквы</p>
                                )}
                            </label>

                            <label className={errors.email ? 'error' : ''}>
                                <span>E-mail</span>
                                <input {...register('email', {
                                    required: true,
                                    pattern:  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                })} placeholder='Введите ваш E-mail...' type="text" />
                                {errors.email && errors.email.type === 'required' && (
                                    <p>Поле обязательно для заполнения</p>
                                )}
                                {errors.email && errors.email.type === 'pattern' && (
                                    <p>Введите корректный email</p> 
                                )}
                            </label>
                    <button type='submit' className='btn-form'>
                        Скачать
                    </button>
                </form>
            </div>
        </Modal>
    )
}

export default FormTomato;