import React from "react";
import MainSlider from "../components/main_page/MainSlider";
import PopularCategory from "../components/main_page/PopularCategory";
import PopularProducts from "../components/main_page/PopularProducts";
import AboutShop from "../components/main_page/AboutShop";

 const Home: React.FC = () => {
    
    return (
        <>
            <MainSlider />
            <PopularCategory />
            <PopularProducts />
            <AboutShop />
        </>
    );
};
export default Home;