import React from "react";
import qs from 'qs';
import debounce from "lodash.debounce";
import { useNavigate } from "react-router-dom";

import { setSearch, setCurrentPage, setLocation, setCategoryId } from "../redux/slices/filterSlice";
import { setSelectedCategories } from "../redux/slices/categorySlice";
import { useSelector } from 'react-redux';



import Sort, { filterList } from "../components/Sort";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { Pagination, TextField, useMediaQuery } from "@mui/material";
import ProductCard from "../components/ProductCard";
import Categories from "../components/Categories";
import SkeletonProduct from "../components/Skeletons/SkeletonProduct";
import { fetchProducts } from "../redux/slices/productsSlice";
import { RootState, useAppDispatch } from "../redux/store";
import { setFilterMobileOpen } from "../redux/slices/filterSlice";




const Catalog: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isSearch = React.useRef(false);
    const isMounted = React.useRef(false);
    const isMobileCategory = useMediaQuery('(max-width: 1200px)');

    const [valueInput, setValueInput] = React.useState('');

    // const [totalPages, setTotalPages] = React.useState(0);


    const { items, status, totalPage } = useSelector((state: RootState) => state.products);
    const { filterActive, searchValue, currentPage, categoryId, filterMobileOpen } = useSelector((state: RootState) => state.filter);


    const searchInfo = searchValue ? `&search=${searchValue}` : '';

    const onChangePage = (num: number) => {
        dispatch(setCurrentPage(num))
    }

    const updateSearchValue = React.useCallback(
        debounce((value: string) => {
            dispatch(setSearch(value));

        }, 500),
        []
    )

    const toggleOpenFilter = () => {
        dispatch(setFilterMobileOpen(true))
        document.body.style.overflow = 'hidden';
    }
    const toggleCloseFilter = () => {
        dispatch(setFilterMobileOpen(false))
        document.body.style.overflow = 'unset';
    }


    const onChangeInput = (value: string) => {
        setValueInput(value);
        updateSearchValue(value);
    }


    const getProducts = async () => {
        console.log(searchInfo)
        dispatch(fetchProducts({
            filterActive,
            search: searchValue,
            currentPage,
            categoryId
        }))
    }

    //если изменили параметры и был первый рендер
    React.useEffect(() => {
        if (isMounted.current) {
            const queryString = qs.stringify({
                sortProperty: filterActive.sortProperty === '' ? 'title' : filterActive.sortProperty, 
                categoryId: categoryId,
                currentPage: currentPage
            });
            console.log(queryString)
            navigate(`?${queryString}`);
        }
        isMounted.current = true;
    }, [filterActive.sortProperty, currentPage, categoryId])

    //если был первый рендер, то проверяем URL-параметры и созраняем в redux
    React.useEffect(() => {
        if (window.location.search) {
            const params = qs.parse(window.location.search.substring(1));

            const sort = filterList.find((obj) => obj.sortProperty === params.sortProperty)

            dispatch(setLocation({
                ...params,
                sort

            })
            );
            isSearch.current = true;

        }
        return () => {
            // Обнуляем фильтр категорий при переходе со страницы
            dispatch(setCategoryId(''))
            dispatch(setSelectedCategories([]))
            document.body.style.overflow = 'unset';
        }

    }, [])

    //если был первый рендер то запрашиваем товары
    React.useEffect(() => {
        if (!isSearch.current) {
            getProducts();


        }
        isSearch.current = false;

    }, [filterActive, searchValue, currentPage, categoryId]);







    return (

        <div className="catalog"> 

            <div className="container">
                <Categories />
                <div className="catalog-box">
                    <div className="page-title">Каталог</div>
                    <TextField
                        value={valueInput}
                        label='Поиск товаров...'
                        onChange={(value) => onChangeInput(value.target.value)}
                    />
                    {isMobileCategory
                        ?
                        <div className="mobile-filter__box">
                            <div className="mobile-filter" onClick={toggleOpenFilter}><FontAwesomeIcon icon={faFilter} /><span>Фильтр</span></div>
                            <Sort value={filterActive} />
                            <div onClick={toggleCloseFilter} className={`shadow-bg ${filterMobileOpen ? 'show' : ''}`}></div>
                        </div>

                        :
                        <Sort value={filterActive} />
                    }


                    <div className="catalog-products">

                        {
                            status === 'error' ? (
                                <div>
                                    <h2>Извините на сайте произошла ошибка, зайдите позже</h2>
                                </div>
                            ) : (
                                status === 'loading' ? (
                                    [...new Array(9)].map((_, index) => <SkeletonProduct key={index} />)
                                ) : (
                                    items.length > 0 ? (
                                        items.map((obj) => (
                                            <ProductCard
                                                key={obj.id}
                                                id={obj.id}
                                                name={obj.name}
                                                price={obj.price}
                                                images={obj.images}
                                                short_description={obj.short_description}
                                                stock_status={obj.stock_status}
                                            />
                                        ))
                                    ) : (
                                        <div>
                                            <h2>Нет доступных товаров</h2>
                                        </div>
                                    )
                                ))

                        }





                        <Pagination
                            count={totalPage}
                            page={currentPage}
                            onChange={(_, num) => onChangePage(num)}

                        />
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Catalog;