import React from 'react';
import { axiosClient, CONSUMER_KEY, CONSUMER_SECRET  } from '../core/config';
import { Swiper as SwiperClass } from 'swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAnglesDown, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductCard from '../components/ProductCard';
import SkeletonProduct from '../components/Skeletons/SkeletonProduct';
import FormChrysanthemum from '../components/FormChrysanthemum';

 
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import "swiper/css/pagination";
import 'swiper/css/thumbs';
import { Pagination } from 'swiper/modules';

// @ts-ignore
import Image1 from '../assets/img/curse1.JPG';
// @ts-ignore
import Image2 from '../assets/img/curse2.JPG';
import { ProductItem } from '../redux/slices/productsSlice';

const EducationChrysanthemum: React.FC = () => {

    const secondSectionRef = React.useRef<HTMLDivElement>(null);

    const scrollToNextSection = () => {
        if (secondSectionRef.current) {
            console.log(secondSectionRef.current)
            secondSectionRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const [openModal, setOpenModal] = React.useState(false)
    const [similarProducts, setSimilarProducts] = React.useState<ProductItem[] | null>(null);
    const [sliderArrow, setSliderArrow] = React.useState<SwiperClass | null>(null)
    const showThreeItems = useMediaQuery('(max-width: 1300px)')
    const showTwoItems = useMediaQuery('(max-width: 800px)') 
    const showOneItem = useMediaQuery('(max-width: 550px)')

    const clickBtnOpen = () => {
        setOpenModal(true)
    }
    const clickBtnClose = () => {
        setOpenModal(false)
    }

    React.useEffect(() => {
        async function fetchProducts() {
            try {
                const {data} = await axiosClient.get('/wp-json/wc/v3/products?category=35',
                {
                    params: {
                        consumer_key: CONSUMER_KEY,
                        consumer_secret: CONSUMER_SECRET,
                        
                    },
                })
                setSimilarProducts(data)
            }
            catch(err) {
                console.log('Ошибка в запросе хризантем', err);
            }
        }
        fetchProducts()
    }, [])
    return (
        <div className="course">
            <FormChrysanthemum openModal={openModal} closeWindow={clickBtnClose} />
            <div className='course__first-screen'>
                <img src="https://dev.yakovogorodnik.ru/wp-content/uploads/2024/01/sliderimg.jpg" alt="" />
                <div className="course__first-screen-info">
                    <div className="title">
                        ХРИЗАНТЕМА МУЛЬТИФЛОРА ВЫРАЩИВАНИЕ И БИЗНЕС-СЕКРЕТЫ
                    </div>
                    <div className="text">
                        Авторский курс от Якова Байдака <br /> автора канала “ТЕПЛИЧНЫЙ БИЗНЕС В ДЕРЕВНЕ” для начинающих садоводов и фермеров.
                    </div>
                    <div className="btn main-slider__link" onClick={clickBtnOpen}>
                        Заказать
                    </div>
                </div>
                <div onClick={scrollToNextSection} className="icon-down"> <FontAwesomeIcon icon={faAnglesDown} /></div>

            </div>
            <div ref={secondSectionRef} className='course__second-screen'>
                <div className="container">
                    <div className="title-line">
                        <span>About course</span>
                        <div className="line"></div>
                    </div>
                    <h2 className="section-title">О курсе</h2>

                    <div className="box-flex">
                        <div className="course__second-screen__info">
                            <div className="course__second-screen__info-title">
                                Хризантемы: все секреты выращивания

                            </div>
                            <div className="course__second-screen__info-text">
                                Этот материал в формате электронной книги, в которую я вложил весь свой опыт и знания, накопленные годами. Здесь собрано максимум нужной информации по выращиванию мультифлоры (и других низкорослых видов хризантем) от а до я, а также бизнес-заметки из своего опыта.
                            </div>

                        </div>
                        <img className="course__second-screen__img" src={Image1} alt="" />
                    </div>
                    <div className="box-flex">
                        <img className="course__second-screen__img" src={Image2} alt="" />
                        <div className="course__second-screen__info">
                            <div className="course__second-screen__info-title">
                                В чем уникальность данного материала?

                            </div>
                            <div className="course__second-screen__info-text">

                                В одном месте собрана подробная информация о каждом этапе развития растения (буквально каждую неделю жизни растения) - максимально подробно расписанны процессы ухода, обработок, подкормок, многих других агротехнических приемов. материал в формате книги, которая всегда под рукой, где есть ответ на любой вопрос который возникает в процессе выращивания. А так же отдельная часть книги посвященная бизнесу на хризантеме, если вы преследуете цель заработать - будет ценная информация.
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="course__third-screen">
                <div className="container">
                    <div className="title-line">
                        <span>Questions and answers</span>
                        <div className="line"></div>
                    </div>
                    <h2 className="section-title">Вопрос и ответ</h2>
                    <div className="course__third-screen__info">
                        <div className="info-item">
                            <div className="title">
                                Полезен ли мне этот курс если совсем нет опыта?

                            </div>
                            <div className="text">
                                - Да! Все написано понятным простым языком для новичков - доступно, последовательно, с иллюстрациями, с удобными таблицами.
                            </div>
                        </div>
                        <div className="info-item">
                            <div className="title">
                                Могу ли я заработать на выращивании хризантемы, если я новичок?

                            </div>
                            <div className="text">
                                - Сможете! этот материал для вас. во второй части книги я подробно описал процессы заработка, способы продаж, маркетинговые приемы и личные бизнес-секреты. Хризантема - относительно неприхотливое растение, которое под силу вырастить любому вовичку в растениеводстве. А данный материал будет незаменимым помощником в процессе выращивания от высадки черенка до продажи.
                            </div>
                        </div>
                        <div className="info-item">
                            <div className="title">
                                Какие преимущества курса перед информацией, доступной в интернете?


                            </div>
                            <div className="text">

                                Курс предоставляет структурированную и проверенную информацию, адаптированную специально для начинающих. Это позволяет избежать путаницы и недостоверной информации, которая часто встречается в сети.

                            </div>
                        </div>
                        <div className="info-item">
                            <div className="title">
                                Как получить доступ?

                            </div>
                            <div className="text">
                                - Нажмите на кнопку “Заказать” , оставьте свой электронный адрес, в течении суток я с вами свяжусь и дам дальнейшую инструкцию.
                            </div>
                        </div>
                        <div className="btn" onClick={clickBtnOpen}>
                            Заказать
                        </div>
                    </div>
                </div>

            </div>
            <div className="similar-products">
                <div className="container">
                    <div className="title-line">
                        <span>chrysanthemum</span>
                        <div className="line"></div>
                    </div>

                    <div className="box-flex">
                        <h2 className="section-title">
                            Хризантемы
                        </h2> 
                        <div className="buttons-swiper">
                            <button className='arrow-swiper arrow-swiper-left' onClick={() => sliderArrow?.slidePrev()}><FontAwesomeIcon icon={faAngleLeft} /></button>
                            <button className='arrow-swiper arrow-swiper-right' onClick={() => sliderArrow?.slideNext()}><FontAwesomeIcon icon={faAngleRight} /></button>
                        </div>
                    </div>

                    <div className="slider-default">


                        <Swiper
                            spaceBetween={50}
                            slidesPerView={showOneItem ? 1 : showTwoItems ? 2 : showThreeItems ? 3 : 4}
                            modules={[Pagination]}
                            pagination={{ clickable: true }}
                            className="mySwiper3"
                            onSwiper={(swiper) => setSliderArrow(swiper)}
                        >
                            {
                                !similarProducts ? [...new Array(6)].map((_, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div className="slide-loaded">
                                                <SkeletonProduct />
                                            </div>
                                        </SwiperSlide>
                                    )

                                })
                                    : similarProducts.map((obj) => {
                                        return (
                                            <SwiperSlide
                                                key={obj.id}
                                            >
                                                <ProductCard

                                                    key={obj.id}
                                                    id={obj.id}
                                                    stock_status={obj.stock_status}
                                                    images={obj.images}
                                                    name={obj.name}
                                                    short_description={obj.short_description}
                                                    price={Number(obj.price)}

                                                />
                                            </SwiperSlide>
                                        )
                                    })


                            }
                        </Swiper>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default EducationChrysanthemum;