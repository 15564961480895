import React from "react";
import { ID_LIST, axiosClient } from "../../core/config";
import { useMediaQuery } from "@mui/material";
import SkeletonPopularCategory from "../Skeletons/SkeletonPopularCategory";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import "swiper/css/pagination";


// import required modules
import { Pagination } from 'swiper/modules';

type TPopularCategory = {
  category_img: {
    ID: number,
    url: string,
    
  }
  title_category: string,
  link_category: string
  
}


const PopularCategory: React.FC = () => {
  const isSlider = useMediaQuery('(max-width: 800px)');
  const oneItem = useMediaQuery('(max-width: 550px)');

  const [popularCategoryItems, setPopularCategoryItems] = React.useState<TPopularCategory[]>()

 
  React.useEffect(() => {
    const fetchPopularCategory = async () => {
       
        try {
            const {data} = await axiosClient.get(`wp-json/wp/v2/pages/${ID_LIST.main_page_id}`);
           setPopularCategoryItems(data.ACF.popular_category) 
           
        } catch (err) {
            console.log('ошибка запроса популярных категорий ' + err);
        }
       
    };
    fetchPopularCategory()
}, [])
  return (
    <section className="main-category">
      <div className="container">
        <div className="title-line">
          <span>Categories</span>
          <div className="line"></div>
        </div>
        <h2 className="section-title">Популярные категории</h2>
        <div className="main-category__wrapper">
          {
          isSlider 
          
          ? 
          
          <div className="slider-default">


                        <Swiper
                            spaceBetween={10}
                            slidesPerView={oneItem ? 1 : 2}
                            modules={[Pagination]}
                            pagination={{ clickable: true }}
                            className="swiperCategory"
                            
                        >
                            {
                                !popularCategoryItems ? [...new Array(6)].map((_, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div className="main-category__loaded">
                                                <SkeletonPopularCategory />
                                            </div>
                                        </SwiperSlide>
                                    )

                                })
                                    : popularCategoryItems.map((obj) => {
                                        return (
                                            <SwiperSlide
                                                key={obj.category_img.ID}
                                            >
                                               <Link to={obj.link_category} className="main-category__item"  key={obj.category_img.ID}>
                                                  <img src={obj.category_img.url} alt="category" />
                                                  <span>{obj.title_category}</span>
                  </Link>
                                            </SwiperSlide>
                                        )
                                    })


                            }
                        </Swiper>

                    </div>


          : 
          
            !popularCategoryItems ? [...new Array(6)].map((_, index) => {
              return(
                <div className='main-category__loaded' key={index}>
                                  <SkeletonPopularCategory />
                              </div>
              );
            })
            : popularCategoryItems.map((obj) => { 
            
                return (
                  <Link to={obj.link_category} className="main-category__item"  key={obj.category_img.ID}>
                    <img src={obj.category_img.url} alt="category" />
                    <span>{obj.title_category}</span>
                  </Link>
                )
  
              })
          
          }
       
          
        </div>
        <a className="link-catalog" href="/catalog">Перейти в каталог</a>
      </div>
    </section>
  );
}
export default PopularCategory;