import { PayloadAction, createSlice } from "@reduxjs/toolkit";


type infoClientOrder = {
    [key: string]: string;
}
interface TInfoClientSlice {
   infoClientOrder: infoClientOrder
}


const initialState: TInfoClientSlice = {
   infoClientOrder: { 
    name: '',
    phone: '',
    email: '',
    country: '',
    address: '',
    locality : '',
    region: '',
    postcode: '',
   }
} 

const infoClientSlice = createSlice({
    name: 'infoClient',
    initialState,
    reducers:{
        setInfo(state, action: PayloadAction<TInfoClientSlice>) {
            const { name, phone, email, country, address, locality, region, postcode } = action.payload.infoClientOrder;
            state.infoClientOrder.name = name;
            state.infoClientOrder.phone = phone;
            state.infoClientOrder.email = email;
            state.infoClientOrder.country = country;
            state.infoClientOrder.address = address;
            state.infoClientOrder.locality = locality;
            state.infoClientOrder.region = region;
            state.infoClientOrder.postcode = postcode;
        }, 
        clearInfo(state) {
            state.infoClientOrder = {
                name: '',
                phone: '',
                email: '',
                country: '',
                address: '',
                locality : '',
                region: '',
                postcode: '', 
            }
        }
    }
})

export const { setInfo, clearInfo} = infoClientSlice.actions;

export default infoClientSlice.reducer