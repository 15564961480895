import { calcTotalPrice } from "./calcTotalPrice";

export const getCartFromLocalStorage = () => {
   
    const data = localStorage.getItem('cart');
    const json = data ? JSON.parse(data) : [];
    const totalPrice = calcTotalPrice(json);

    if(json.length) {
        return {
            items: json,
            totalPrice
        }
    } else {
        return {
            items: [],
            totalPrice: 0
        }
        
    }
    
}  