import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TCartItem, addItem, cartShow } from "../../redux/slices/cartSlice";
import { ProductItem } from "../../redux/slices/productsSlice";
import { RootState } from "../../redux/store";


const ProductCard: React.FC<ProductItem> = ({ id, name, price, images, stock_status, short_description }) => {
  const dispatch = useDispatch();
  const [inCart, setInCart] = React.useState(false);
  const { items } = useSelector((state: RootState) => state.cart)
  const removeHtmlTags = (htmlString: string) => {
    const doc = new DOMParser().parseFromString(htmlString, 'text/html');
    return doc.body.textContent || "";
  };

  const foundItem = items.find(i => i.id === id);
  const renderCartButton  = () => {
      if (!foundItem) {
        setInCart(false)
      } else {
        setInCart(true)
      }
      
    }
    React.useEffect(() => {
      renderCartButton()
    }, [items])
    

  const onClickAdd = () => {
    const item: TCartItem = {
      id,
      name,
      price,
      img: images,
      count: 1
    };
    dispatch(addItem(item))
    setInCart(true);
    dispatch(cartShow())
  }
 

  return (
    <div className="product-card">
      {
        stock_status === 'instock' ? <div className="status on">В наличии</div>
          : <div className="status off">Нет в наличии</div>
      }
      <Link to={`/product/${id}`} className="link_img"><img className="product-card__img" src={images[0] ? images[0].src : ''} alt="product" /></Link>
      <div className="product-card__info">
        <Link to={`/product/${id}`} className="product-card__title" >{name}</Link>
        <div className="product-card__type">
          {removeHtmlTags(short_description)}
        </div>
        <div className="product-card__info-bottom">
          <div className="price"><span className="price-text">Цена:</span>
            <div className="price-value">{price} ₽</div>
          </div>
          {stock_status === 'instock' ?
            !inCart
              ?
              <button onClick={onClickAdd} type="button" className="btn-buy">Купить</button>
              :
              <div className="btn-buy added">В корзине</div>
            :
            <div></div>}



        </div>
      </div>
    </div>
  );
}
export default ProductCard;