import React from 'react';

const LegalInformation: React.FC = () => {
    return (
        <div className="container legal-information">

            <h1 className="polic-title">Контакты</h1>

            <h2>Наш телефон: </h2>



            <a href="tel:+79009325534">+79009325534</a>
            <p>График работы: с 10:00 до 18:00 по московскому времени</p>
            



            <h2>Почта:</h2>



            <a href="mailto:yakov.ogorodnik@mail.ru">yakov.ogorodnik@mail.ru</a>
           
<p> Предназначена для обратной связи с покупателями. Напишите нам, если у вас есть вопросы по продукции, качеству обслуживания, оплате или сроках доставки. Мы обязательно ответим вам в течение 48 часов.</p>


            <h2>Юридическая информация:</h2>



            <p>Реквизиты ИП Байдак Яков Яковлевич;</p>



            <p>ИНН — 361006588059;</p>



            <p>ОГРНИП — 323366800115018;</p>



            <h3>Банковские реквизиты:</h3>



            <p>ООО «Банк Точка»;</p>



            <p>Р/С — 40802810620000182812;</p>



            <p>К/С — 30101810745374525104;</p>



            <p>БИК — 044525104;</p>



            <h3>Юридический адрес:</h3>



            <p>397560, РОССИЯ, обл ВОРОНЕЖСКАЯ, р-н ВОРОБЬЕВСКИЙ, п ЦЕНТРАЛЬНОЙ УСАДЬБЫ СОВХОЗА «ВОРОБЬЁВСКИЙ», ул ОГОРОДНЯЯ, ДОМ 23</p>
        </div>
    )
}
export default LegalInformation;