import { createSlice } from "@reduxjs/toolkit";





const initialState = {
    menuOpen: false
} 

const mobileMenu = createSlice({
    name: 'mobileMenu',
    initialState,
    reducers:{
        
        toggleMobileMenu(state, action) {
           state.menuOpen = action.payload
        }
    }
})

export const {toggleMobileMenu} = mobileMenu.actions;

export default mobileMenu.reducer