import React from 'react';
import { ID_LIST, axiosClient } from '../core/config';
import LightGallery from 'lightgallery/react';
import SkeletonReview from '../components/Skeletons/SkeletonReview';

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';

import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';


type TReviews = {
    reviews: {
        url: string,
        ID: number
    }
}

const Reviews: React.FC = () => {
    const onInit = () => {
        
    };
const [itemsReviews, setItemsReviews] = React.useState<TReviews[]>()
    React.useEffect(() => {
        const fetchItemsReviews = async () => {
           
            try {
                const {data} = await axiosClient.get(`wp-json/wp/v2/pages/${ID_LIST.reviews_page_id}`);
                setItemsReviews(data.ACF.reviews_photo)
               
               
               
            } catch (err) {
                console.log('ошибка запроса отзывов ' + err);
            }
           
        };
        fetchItemsReviews()
        
    }, [])

    return (
        <div className="page-reviews">
            <div className="container">
                <div className="page-title">
                    Отзывы
                </div>

                {
                !itemsReviews ? <div className='box-reviews loaded'> {[...new Array(12)].map((_, index) => {
                    return (
                        <SkeletonReview  key={index} />
                    )
                    })} </div> : <LightGallery 
                onInit={onInit}
                speed={500}
                plugins={[lgThumbnail, lgZoom]}
                elementClassNames="box-reviews"
            >
                {
                    itemsReviews.map((obj) => {
                        
                        return (
                            <a className="item-review" href={obj.reviews.url} key={obj.reviews.ID}>
                                <img alt="Отзыв" src={`${obj.reviews.url}`} />
                            </a>
                        )
                    })
                }
                
              

            </LightGallery>
            
            }


                



            </div>
        </div>
    );
}

export default Reviews;