import React from 'react';
import { useMediaQuery } from '@mui/material';

import { plusItem, minusItem, removeItem, TCartItem } from '../../redux/slices/cartSlice';
import { useDispatch } from 'react-redux';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';


const CartItem: React.FC<TCartItem> = ({ id, count, price, name, img }) => {
    const dispatch = useDispatch();

    const cartItemMobile = useMediaQuery('(max-width: 600px)')

    const onClickPlus = () => {
       
        dispatch(plusItem(id))
    }
    const onClickMinus = () => {
        dispatch(minusItem(id))
    }
    const onClickRemove = () => {
       
        dispatch(removeItem(id))
    }


    return (
        cartItemMobile ? 
        <div className="cart__product-card mobile">
            <img className="img__product" src={img[0] ? img[0].src : ''} alt="" />
            <div className="box-title-price">
                <div className="product__title">
                        {name}
                </div>
                <div className="price">
                        <div className="title__column">
                            ЦЕНА
                        </div>

                        <div className="price__value">
                            {price} <span>руб.</span>
                        </div>


                </div>
            </div>
            <div className="info__product">
               
                <div className="info__bottom">
                    
                    <div className="amount">
                        <div className="title__column">
                            КОЛИЧЕСТВО
                        </div>
                        <div className="amount__info">
                            <button disabled={count === 1} onClick={onClickMinus} className='minus'>  <FontAwesomeIcon icon={faMinus} /></button>
                            <div className='amount__value'>{count}</div>


                            <button onClick={onClickPlus} className="plus"><FontAwesomeIcon icon={faPlus} /></button>
                        </div>
                        <div onClick={onClickRemove} className="btn-delete"><FontAwesomeIcon icon={faClose} />Удалить товар</div>
                    </div>

                    <div className="total">
                        <div className="title__column">ИТОГО</div>
                        <div className="total__value">
                            {price * count} <span>руб.</span>
                        </div>
                    </div>
                </div>


            </div>
        </div>
        : 
        <div className="cart__product-card">
        <img className="img__product" src={img[0] ? img[0].src : ''} alt="" />
        <div className="info__product">
            <div className="info__top">
                <div className="product__title">
                    {name}
                </div>
                <div onClick={onClickRemove} className="btn-delete"><FontAwesomeIcon icon={faClose} /></div>
            </div>
            <div className="info__bottom">
                <div className="price">
                    <div className="title__column">
                        ЦЕНА
                    </div>

                    <div className="price__value">
                        {price} <span>руб.</span>
                    </div>


                </div>
                <div className="amount">
                    <div className="title__column">
                        КОЛИЧЕСТВО
                    </div>
                    <div className="amount__info">
                        <button disabled={count === 1} onClick={onClickMinus} className='minus'>  <FontAwesomeIcon icon={faMinus} /></button>
                        <div className='amount__value'>{count}</div>


                        <button onClick={onClickPlus} className="plus"><FontAwesomeIcon icon={faPlus} /></button>
                    </div>
                </div>

                <div className="total">
                    <div className="title__column">ИТОГО</div>
                    <div className="total__value">
                        {price * count} <span>руб.</span>
                    </div>
                </div>
            </div>


        </div>
    </div>
    );
}

export default CartItem;