import React from 'react';

import { Link } from 'react-router-dom';

const  HeaderCheckout: React.FC = () => {
    return (
        <header className='header header-checkout'>
            <div className="container">
                <Link to="/" className="logo">Yakov</Link>   
            </div>
        </header>
    );
}
 
export default HeaderCheckout;  