import React from 'react';

import ResponsiveHeader from '../ResponsiveHeader';
import Footer from '../Footer';




const HomeLayouts: React.FC<{children: JSX.Element}> = ({children}) => {
  return (
    <>
         <ResponsiveHeader />
            {children}
          <Footer /> 
    </>
  )
}

export default HomeLayouts
