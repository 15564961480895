import React from 'react'
import classNames from 'classnames'
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, FormControlLabel, } from '@mui/material';
import { Category, addSelectedCategory, removeSelectedCategory } from '../../redux/slices/categorySlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

interface CategoryItemProps extends Category {
    lastLevel?: number
    active?: boolean
    defaultOpened?: boolean
    onClick?: (id: number) => void
    onSelectCategory?: (categoryId: number, checked: boolean) => void
}

interface SubcategoryProps {
    items?: CategoryItemProps[]
    level?: number
    active?: boolean
    onSelectCategory?: (categoryId: number, checked: boolean) => void
}

/**
 * Вложенный мини компонент для отображения подкатегорий
 * Рекурсивно выводит список категорий (бесконечно, пока есть вложенные у вложеннх и тд)
 * @param param0 
 * @returns
 */
const Subcategory: React.FC<SubcategoryProps> = ({ items, active, level = 1, onSelectCategory }) => {

    // Если у элемента нет вложенных подкатегорий, то ничего не рендерим
    if (!Array.isArray(items) || items.length === 0) return <></>

    return (
        <ul className={classNames(`category-list category-list__sub category-list--lvl-${level}`, {
            'active': active,
        })}>
            {items.map((item) => (
                <CategoryItem
                    key={`sub-${level}-${item.id}`}
                    lastLevel={level}
                    onSelectCategory={onSelectCategory}
                    {...item}
                />
            ))}
        </ul>
    )
}

/**
 * Основной компонент Элемент категории
 * @param item 
 * @returns 
 */
const CategoryItem: React.FC<CategoryItemProps> = (item) => {
    const { id, name, children, parent, defaultOpened = false, active, lastLevel = 0, onClick } = item;
    const dispatch = useDispatch(); 
    const selectedCategories = useSelector(({ category }: RootState) => category.selectedCategories)


    // Свернуть или развернуть подкатегории с помощью стрелочки
    const [subcategoryActive, setSubcategoryActive] = React.useState(false);

    /**
     * По умолчанию показываем или скрываем список подкатегорий (это нужно для основного списка, чтобы первый уровень показывался)
     */
    React.useEffect(() => {
        setSubcategoryActive(defaultOpened)
    }, [defaultOpened])

    /**
     * Есть ли вложенные категории
     * @returns 
     */
    const hasChild = () => {
        if (!Array.isArray(children)) return false;
        return children.length > 0
    }

    /**
     * Свернуть / развернуть меню подкатегорий
     * @param e 
     */
    const handleClickSubBtn = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.preventDefault();
        setSubcategoryActive(prev => !prev)
    }

    /**
     * Обработчик клика по чекбоксу
     * @param event 
     * @param checked 
     */
    const handleClickCheckbox = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        const { id, name, children, parent } = item;
        const storeItem = { id, name, children, parent }

        if (checked) {
            dispatch(addSelectedCategory(storeItem))
        } else {
            dispatch(removeSelectedCategory(storeItem))
        }
    }

    // Проверка, отмечен ли чекбокс (ищем в redux, отмечена эта категория или нет)
    const isChecked = () => {
        return !!selectedCategories.find(category => category.id === id)
    }

    /**
     * В чекбоксе рисует не галочку, а полосочку, если снял выделение со вложенных элементов
     */
    const isIndeterminate = () => {
        if (!Array.isArray(children)) return false;
        const selectedSub = selectedCategories.filter(c => c.parent === id);
        let lengthMatch = false;
        if (selectedSub.length === 0) {
            lengthMatch = false;
        } else if (selectedSub.length < children.length) {
            lengthMatch = true;
        }

        return isChecked() && lengthMatch;
    }

    return (
        <li
            key={id}
            className={classNames(`category-item category-item--lvl-${lastLevel || 0}`, {
                'has-subcategories': hasChild(),
            })}
        >
            <div
                className={classNames('category-item__title', {
                    'active': active,
                })}
            >
                <FormControlLabel
                    control={(
                        <Checkbox
                            checked={isChecked()}
                            indeterminate={isIndeterminate()}
                            onChange={handleClickCheckbox}

                        />
                    )}
                    label={<span className='category-item__title-text'>{name}</span>}
                />

                {/* Стрклочка для разворачивания подкатегорий */}
                {hasChild() && <span onClick={handleClickSubBtn} className={classNames('btn-category', { 'active': subcategoryActive })}> <FontAwesomeIcon icon={faAngleDown} /></span>}
            </div>

            {/* Выводит подкатегории, если они есть */}
            <Subcategory
                active={subcategoryActive}
                items={children}
                level={lastLevel + 1}
            />
        </li>
    )
}

export default CategoryItem