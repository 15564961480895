import { configureStore } from '@reduxjs/toolkit';
import  filter  from './slices/filterSlice';
import cart from './slices/cartSlice';
import products from './slices/productsSlice';
import category from './slices/categorySlice';
import infoClient from './slices/infoClientSlice';
import mobileMenu from './slices/mobileMenuSlice';
import { useDispatch } from 'react-redux';

export const store = configureStore({
  reducer: {
    filter,
    cart,
    products,
    category,
    infoClient,
    mobileMenu
  },
});

export type RootState = ReturnType<typeof store.getState>;

type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
