import React from "react";
import Cart from "./Cart";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { cartShow } from "../redux/slices/cartSlice";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faSeedling } from '@fortawesome/free-solid-svg-icons';
import { RootState } from "../redux/store";



const Header: React.FC<{fixed?: boolean}> = ({fixed}) => {
    const dispatch = useDispatch();
    
    
    const {items, totalPrice, cartOpen} = useSelector((state: RootState) => state.cart);
    const totalCount = items.reduce((sum, item) => sum + item.count, 0);
    const isMounted = React.useRef(false)

 React.useEffect(() => {
    if(isMounted.current) {
        const json = JSON.stringify(items);
        localStorage.setItem('cart', json)
    }
    isMounted.current = true
 }, [items])
    const openCartClick = () => { 
        dispatch(cartShow())
    }
    return (
        <header className={classNames('header', {
            'main-page' : fixed,
        })}>
            <div className="container">
                <Link className="logo" to="/">Yakov</Link>
                <Link className="header__catalog" to="/catalog">
                <FontAwesomeIcon icon={faSeedling} />
                    Каталог товаров
                </Link>
                <nav>
                    <ul className="header__menu"> 

                        <li className="header__menu-item">
                            <Link to='/' className="header__menu-link">Главная</Link>
                        </li>
                     
                        <li className="header__menu-item">
                            <Link to='/reviews' className="header__menu-link">Отзывы</Link>
                        </li>
                        <li className="header__menu-item">
                            <Link to='/education' className="header__menu-link">Обучение</Link>
                        </li>
                        <li className="header__menu-item">
                            <Link to='/contacts' className="header__menu-link" >Контакты</Link>
                        </li>

                        <li className="header__menu-item">
                            <Link to='/payment-delivery' className="header__menu-link">Оплата/доставка</Link>
                        </li>
                        
                    </ul>
                </nav>
                <div className="header__right">
                    
                    <button onClick={openCartClick} className="header__cart">
                        <span className="total-price">{totalPrice} ₽</span>
                        <span className="total-items">
                        <FontAwesomeIcon icon={faCartShopping} />
                            <span className="value">{totalCount}</span>
                        </span>
                    </button>
                </div>
            </div>
            <Cart openCart={cartOpen} />
        </header>
    );

}

export default Header;