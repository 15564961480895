import React from "react";
import { axiosClient, CONSUMER_KEY, CONSUMER_SECRET } from "../../core/config";
import { useSelector } from 'react-redux';
import { useAppDispatch, RootState } from "../../redux/store";
import { setCategoryId, setFilterMobileOpen } from "../../redux/slices/filterSlice";
import Stack from '@mui/material/Stack';
import { Badge, Button } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import _ from 'lodash' // Нужно установить библиотеку lodash: npm i lodash
import CategoryItem from "./CategoryItem";
import CircularProgress from '@mui/material/CircularProgress';
import { Category, setSelectedCategories } from "../../redux/slices/categorySlice";

// Если будет ошибка установки, то: npm i lodash --force



const Categories: React.FC = () => {
    const dispatch = useAppDispatch();
    const btnMobile = useMediaQuery('(max-width: 1200px)')

    const [categories, setCategories] = React.useState<Category[] | undefined>(undefined)
    const selectedCategories = useSelector((state: RootState) => state.category.selectedCategories)
    const {filterMobileOpen} = useSelector((state: RootState) => state.filter)

    const toggleCloseFilter = () => {
        dispatch(setFilterMobileOpen(false))
        document.body.style.overflow = 'unset';
    }


    React.useEffect(() => {
        const fetchCategorys = async () => {
           
            try {
                const { data } = await axiosClient.get<Category[]>('wp-json/wc/v3/products/categories/',
                {
                    params: {
                        consumer_key: CONSUMER_KEY,
                        consumer_secret: CONSUMER_SECRET,
                        per_page: 100
                    }
                });


                /**
                 * Эта вся кракозябра нужна, чтобы сгруппировать список категорий
                 * У тебя от вордпресса приходит просто пассив: [{id: 1, name: '', parent: 0}, {id: 2, name: '', parent: 1}]
                 * Этой хуйней мы делаем так: [{id: 1, name: '', children: [{id: 2, name: '', parent: 1}], }]
                 * Сгруппировал список категорий, чтобы была вложенность и потом выводить их в списке
                 */
                const grouped = _.groupBy(data, (category) => category.parent);

                //@ts-ignore
                function groupCategoryItems(parent: number | null) {
                    let groupedList: Category[] = [];
                    if (parent !== null) {
                        groupedList = grouped[parent] || [];
                    }

                    // @ts-ignore
                    return groupedList.map(({ id, name, parent, ...rest }: Category) => {
                        let subCategory: Category[] = groupCategoryItems(id);
                        let subCategoryList: Pick<Category, 'children'> = {};

                        if (Array.isArray(subCategory) && subCategory.length > 0) {
                            subCategoryList = {
                                children: subCategory
                            }
                        }

                        return {
                            id: id,
                            name: name,
                            parent,
                            ...rest,
                            ...subCategoryList,
                        }
                    })
                }

                const categories = groupCategoryItems(0);

                setCategories(categories)
            } catch (err) {
                console.log('ошибка запроса категорий ' + err);
            }

        };
        fetchCategorys()
    }, [])

    /**
     * Применяем фильтры категорий при нажатии на кнопку применить
     */
    const acceptFilters = () => {
        let categoryIdList = selectedCategories.map((c) => c.id) // У тебя в store хранится массив объектов, с помощью map получаем только массив id
        let categoryString = categoryIdList.join(',') // преобразуем массив id в строку [1,2,3] => "1,2,3"
        dispatch(setCategoryId(categoryString)) // Применяем фильтры
        dispatch(setFilterMobileOpen(false))
        document.body.style.overflow = 'unset';
    }

    /**
     * Сбрасываем фильтры категорий
     */
    const resetFitlers = () => {
        dispatch(setCategoryId(''))
        dispatch(setSelectedCategories([]))
        dispatch(setFilterMobileOpen(false))
        document.body.style.overflow = 'unset';
    }


    return (
        <div className={`catalog-filter ${filterMobileOpen === true ? 'catalog-filter__open' : ''}`}>
            <span className="title">Категории: {btnMobile ? <div onClick={toggleCloseFilter} className="close-filter"><FontAwesomeIcon icon={faXmark} /></div> : <></>} </span>
            {
                !categories 
                    ? (
                        <div style={{ textAlign: 'center', padding: '50px 0 50px' }}> {/* добавил прелоадер анимированный */}
                            <CircularProgress size={50} />
                            <div style={{ fontSize: 13, marginTop: 5 }}>Загрузка...</div>
                        </div>
                    )
                    : (
                        <ul className="category-list">
                            {categories.map((category) => (
                                <CategoryItem
                                    key={category.id}
                                    defaultOpened
                                    {...category}
                                />
                            ))}
                        </ul>
                    )
            }

            {/* Добавил футер категорий с кнопками */}
            <div className="catalog-filter__footer">
                <Stack direction="row" spacing={2} alignContent={'center'} justifyContent={'center'}>
                    <Button size="medium" variant="outlined" onClick={resetFitlers}>Сбросить</Button>
                    <Badge badgeContent={selectedCategories.length || 0} color="secondary">
                        <Button size="medium" variant="contained" onClick={acceptFilters}>
                            Применить
                        </Button>
                    </Badge>
                </Stack>
            </div>
        </div>
    )
}

export default Categories;