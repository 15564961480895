import React from 'react';
import { axiosClient, CONSUMER_KEY, CONSUMER_SECRET  } from '../core/config';

import FormCucumber from '../components/FormEducationFree/FormTomato';

 

// @ts-ignore
import Image1 from '../assets/img/tomatoCurse.jpg';



const EducationTomato: React.FC = () => {


   
    const [openModal, setOpenModal] = React.useState(false)
   

    const clickBtnOpen = () => {
        setOpenModal(true)
    }
    const clickBtnClose = () => {
        setOpenModal(false)
    }

 
    return (
        <div className="course">
            <FormCucumber openModal={openModal} closeWindow={clickBtnClose} />
            <div className='course__first-screen'>
                <img src={Image1} alt="" />
                <div className="course__first-screen-info">
                    <div className="title">  
                    ПОСОБИЕ ПО ВЫРАЩИВАЮ ТОМАТОВ  
                    </div>
                    <div className="text">
                    Бесплатное пособие по выращиванию томатов: все, что вам нужно знать для успешного урожая.
                    </div>
                    <div className="btn main-slider__link" onClick={clickBtnOpen}>
                        Скачать 
                    </div>
                </div>
               
            </div>
         
        </div>
    )
}
export default EducationTomato;