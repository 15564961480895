import React from "react";
import { Modal } from "antd";
import './windowsModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
const WindowError: React.FC<{errorSubmit: boolean, closeWindow: any}> = ({errorSubmit, closeWindow}) => {
    
return (
    <Modal className="window" open={errorSubmit} closeIcon={<FontAwesomeIcon icon={faXmark} />} onCancel={closeWindow} footer={null}>
        <div className="window__title window-error__title">Произошла ошибка</div>
        <div className="window__text">Пожалуйста повторите попытку позже</div>
    </Modal>
) 
}

export default WindowError;