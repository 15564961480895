import React from 'react';
import HeaderCheckout from '../HeaderCheckout';
import FooterCheckout from '../FooterCheckout';

const CheckoutLayouts: React.FC<{children: JSX.Element}> = ({ children }) => {
   return (
      <>
         <HeaderCheckout />
         {children} 
         <FooterCheckout />
      </>
   );
}

export default CheckoutLayouts;