import axios from "axios";
export const PRODUCTION: boolean = true; // true - режим разработки | false - ПРОДАКШН
export const API_URL: string = PRODUCTION ? "https://dev.yakovogorodnik.ru/" : "https://yakovogorodnik.ru/"; // Если режим разработки, то запросы будут идти на dev.yakov
export const CONSUMER_KEY = "ck_d54247754025c5f412bc9557c7b812ac4fa7e3a9";
export const CONSUMER_SECRET = "cs_d250d8d9fb538642f785e1f89817fc173356c6b4";
const ID_LIST_DEV = {
    // Тут все id, которые ты в коде вставлял для запрососв. Это для DEV
    main_page_id: 1233,
    education_page_id: 4819,
    reviews_page_id: 1243, // нужный подставь  
    //... остальные id
   };
   const ID_LIST_PROD = {
    // Тут все id, которые ты в коде вставлял для запрососв. Это для Prod
    main_page_id: 2,
    education_page_id: 4819,
    reviews_page_id: 1243, // нужный подставь
    //... остальные id
   }; 
   
   export const ID_LIST = PRODUCTION === true ? ID_LIST_DEV : ID_LIST_PROD; // Если стоит режим разработки, то экспортируется первый объект (ID_LIST_DEV), если продакешн, то второй (ID_LIST_PROD )
   
 

/**
 * В Path - передаешь полный адрес к API и он подставляется к твоему основному адресу
 * если PRODUCTION == true. getApiUrl("wp-json/wc/aboba") - вернет: "https://yakovogorodnik.ru/wp-json/wc/aboba" | Если PRODUCTION == false - вернет: "https://dev.yakovogorodnik.ru/wp-json/wc/aboba"
 * @param path 
 * @returns 
 */
export const axiosClient = axios.create({
    baseURL: API_URL,
});
