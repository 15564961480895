import React from 'react';
import SkeletonFullProduct from '../Skeletons/SkeletonFullProduct';
import { Link } from 'react-router-dom';

import { ID_LIST, axiosClient } from '../../core/config';

const AboutShop: React.FC = () => {

    const [aboutBlock, setAboutBlock] = React.useState<{
        title: string, 
        text: string, 
        list_text: {
            text_list: string,
             svg_icon: {url: string}
            }[],
        img: {url: string}
        }[]>()


    React.useEffect(() => {
        const fetchAboutBlock = async () => {
           
            try {
                const {data} = await axiosClient.get(`wp-json/wp/v2/pages/${ID_LIST.main_page_id}`); 
                setAboutBlock(data.ACF.about_shop)  
               
            } catch (err) {
                console.log('ошибка запроса популярных категорий ' + err);
            }
           
        };
        fetchAboutBlock()
    }, [])


    return (
        <section className='main-about-us'>


            <div className="container">
                {
                    !aboutBlock ? <SkeletonFullProduct></SkeletonFullProduct>
                    :
                    aboutBlock.map((obj) => {
                        return (
                            <div key={obj.title}>
                                <div className="title-line">
                                    <span>About shop</span>
                                    <div className="line"></div>
                                </div>
                                <h2 className="section-title">
                                    {obj.title}
                                </h2>
                                <div className="main-about-us__wrapper">
                                    <div className="main-about-us__info">
                                        <div className="main-about-us__text">
                                            <p>
                                                {obj.text}
                                            </p>
                                        </div>
                                        <div className="main-about-us__items">
                                            {obj.list_text.map((item) => {
                                                return (
                                                    <div className="main-about-us__item" key={item.text_list}>
                                                        <img src={item.svg_icon.url} alt="" />
                                                        <div className="text_list">{item.text_list}</div>
                                                    </div>
                                                )
                                            })}


                                        </div>
                                        <Link to='/payment-delivery' className="link-about" >
                                            узнать больше про доставку и оплату
                                        </Link>
                                    </div>
                                    <div className="main-about-us__img">
                                        <img src={obj.img.url} alt="Цветы" />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }


            </div>
        </section>
    )
}

export default AboutShop;