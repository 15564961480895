import React from "react"
import ContentLoader from "react-content-loader"

const SkeletonPopularCategory = () => (
   
  <ContentLoader 
  className="product-card"
    speed={2}
   
    viewBox="0 0 100 100"
    backgroundColor="#dedede"
    foregroundColor="#ecebeb"

  >
  <rect x="0" y="0" rx="0" ry="0"  style={{height: '100vh', width: "100%"}} />
   
  </ContentLoader>
 
)

export default SkeletonPopularCategory;