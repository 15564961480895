import React from "react";
import { Modal } from "antd";
import './windowsModal.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

const WindowSuccess: React.FC<{successSubmit: boolean, closeWindow: any}> = ({successSubmit, closeWindow}) => {
    return (
        <Modal className="window" open={successSubmit} closeIcon={<FontAwesomeIcon icon={faXmark} />} onCancel={closeWindow} footer={null}>
        <div className="window__title">Форма успешно отправлена</div>
        <div className="window__text">Спасибо за оставленную заявку</div>
        <div className="window__text">Мы ответим вам в течении 24 часов на ваш оставленный Email</div>
        
        
    </Modal>
    )
}

export default WindowSuccess;