import React from "react";

const NotFound: React.FC = () => {
    return(
        <h1>
            404 СТРАНИЦЫ НЕ СУЩЕСТВУЕТ
        </h1>
    );
}

 export default NotFound;