import React from 'react'

const PrivacyPolicy: React.FC = () => {
    return (
        <div className='container privacy-policy'>
            <h1>Политика конфиденциальности</h1>
            <h2>1. Общие положения</h2>
            <p>1.1. Настоящая Политика конфиденциальности (далее — «Политика») действует в отношениях между Пользователями и yakovogorodnik.ru (далее — «Компания»). </p>
            <p>1.2. В рамках настоящей Политики под «персональной информацией Пользователя» понимаются персональные данные, которые предоставляет Пользователь во время оформления заказа, используя сайт, сервисы и программы Компании (далее — «Сервисы»).</p>
            <p>1.3. Компания не проверяет достоверность персональной информации, предоставляемой Пользователями. Однако Компания исходит из того, что Пользователь предоставляет достоверную и достаточную персональную информацию, и поддерживает эту информацию в актуальном состоянии.</p>
            <p>1.4. Данные, которые автоматически передаются Сервисам в процессе их использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе IP-адрес, информация из cookie, информация о браузере Пользователя (или иной программе, с помощью которой осуществляется доступ к Сервисам), время доступа, адрес запрашиваемой страницы не являются персональными данными.</p>
            <p>1.5. Под обработкой персональных данных в настоящей Политике понимается: сбор вышеуказанных данных, их систематизация, накопление, хранение, уточнение (обновление, изменение), использование, блокирование, уничтожение.</p>
            <h2>2. Цели сбора и обработки персональной информации Пользователей</h2>
            <p>2.1. Идентификация Пользователя при исполнении Публичной оферты о продаже товаров дистанционным способом и договоров с Компанией; </p>
            <p>2.2. Направление кассового чека в электронном виде на адрес электронной почты и/или телефон Пользователя.</p>
            <h2>3. Меры, применяемые для защиты персональной информации Пользователей</h2>
            <p>3.1. Компания принимает необходимые и достаточные организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.</p>
            <p>3.2. Компания обеспечивает конфиденциальность предоставленных Пользователем персональных данных, их защиту от копирования, распространения.</p>
            <p>3.3. Компания обязуется предотвращать попытки несанкционированного доступа к персональным данным Пользователей, предоставленных Компании; своевременно обнаруживать и пресекать такие попытки.</p>
            <h2>4. Изменение Политики конфиденциальности</h2>
            <p>4.1. Компания имеет право вносить изменения в настоящую Политику. При внесении изменений в актуальной редакции указывается дата последнего обновления. Пользователь обязуется самостоятельно контролировать наличие изменений в настоящей Политике. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики. Действующая редакция всегда находится на Сайте.</p>
            <p>Дата вступления в силу: 01.01.2024</p>
        </div>
    )
}

export default PrivacyPolicy;