import React from "react";
import Cart from "./Cart";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { cartShow } from "../redux/slices/cartSlice";
import { toggleMobileMenu } from "../redux/slices/mobileMenuSlice";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faSeedling } from '@fortawesome/free-solid-svg-icons';
import { RootState } from "../redux/store";



const HeaderMobile: React.FC<{fixed?: boolean}> = ({fixed}) => {
    const dispatch = useDispatch();
    
    
    const {items, totalPrice, cartOpen} = useSelector((state: RootState) => state.cart);
    const {menuOpen} = useSelector((state: RootState) => state.mobileMenu)
    const totalCount = items.reduce((sum, item) => sum + item.count, 0);
    const isMounted = React.useRef(false);
    
    
    function toggleMenuMode() {
        dispatch(toggleMobileMenu(!menuOpen));
        if (!menuOpen) {
            document.body.style.overflow = 'hidden';
          } else {
            document.body.style.overflow = 'unset'; 
          }
      }
 React.useEffect(() => {
   

    if(isMounted.current) {
        const json = JSON.stringify(items);
        localStorage.setItem('cart', json)
    }
    isMounted.current = true
 }, [items])

 React.useEffect(() => {
  
    return () => {
        dispatch(toggleMobileMenu(false)); 
        document.body.style.overflow = 'unset';
    };
}, []);
    const openCartClick = () => { 
        dispatch(cartShow())
    }
    return (
        <header className={classNames('header header-mobile', {
            'main-page' : fixed,
        })}>
            <div className="container">
                <Link className="logo" to="/">Yakov</Link>
               
              
                <div className="header__right">
                    
                    <button onClick={openCartClick} className="header__cart">
                        <span className="total-price">{totalPrice} ₽</span>
                        <span className="total-items">
                        <FontAwesomeIcon icon={faCartShopping} />
                            <span className="value">{totalCount}</span>
                        </span>
                    </button>
                </div>
                <button className="burger-menu__button" onClick={toggleMenuMode}>
                    <span></span>
                </button>
               
                
               
                    <div className={`burger-menu__content ${menuOpen ? 'open' : ''}`}> 
                        <div className="box-top">
                        <Link className="logo" to="/">Yakov</Link>
                           
                            <button className="burger-menu__button burger-menu__button-close" onClick={toggleMenuMode}>
                                <span></span>
                            </button>
                        </div>
                        <Link className="header__catalog" to="/catalog" onClick={toggleMenuMode}>
                                <FontAwesomeIcon icon={faSeedling} />
                                Каталог товаров
                            </Link>
                <nav>
                    <ul className="header__menu">
                        <li className="header__menu-item">
                            <Link to='/' onClick={toggleMenuMode} className="header__menu-link">Главная</Link>
                        </li>
                      
                        <li className="header__menu-item">
                            <Link to='/reviews' onClick={toggleMenuMode} className="header__menu-link">Отзывы</Link>
                        </li>
                        <li className="header__menu-item">
                            <Link to='/education' onClick={toggleMenuMode} className="header__menu-link">Обучение</Link>
                        </li>
                        <li className="header__menu-item">
                            <Link to='/contacts' onClick={toggleMenuMode} className="header__menu-link" >Контакты</Link>
                        </li>
                        <li className="header__menu-item">
                            <Link to='/payment-delivery' onClick={toggleMenuMode} className="header__menu-link">Оплата и доставка</Link>
                        </li>
                    </ul>
                </nav>
                
                <div className="column social">
             
              <div className="social-text">Мы в соц. сетях:</div>
              <div className="social-list"><a className="social-item vk" href="https://vk.com/id521874877">
                <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="vk" className="svg-inline--fa fa-vk " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path fill="currentColor" d="M31.4907 63.4907C0 94.9813 0 145.671 0 247.04V264.96C0 366.329 0 417.019 31.4907 448.509C62.9813 480 113.671 480 215.04 480H232.96C334.329 480 385.019 480 416.509 448.509C448 417.019 448 366.329 448 264.96V247.04C448 145.671 448 94.9813 416.509 63.4907C385.019 32 334.329 32 232.96 32H215.04C113.671 32 62.9813 32 31.4907 63.4907ZM75.6 168.267H126.747C128.427 253.76 166.133 289.973 196 297.44V168.267H244.16V242C273.653 238.827 304.64 205.227 315.093 168.267H363.253C359.313 187.435 351.46 205.583 340.186 221.579C328.913 237.574 314.461 251.071 297.733 261.227C316.41 270.499 332.907 283.63 346.132 299.751C359.357 315.873 369.01 334.618 374.453 354.747H321.44C316.555 337.262 306.614 321.61 292.865 309.754C279.117 297.899 262.173 290.368 244.16 288.107V354.747H238.373C136.267 354.747 78.0267 284.747 75.6 168.267Z"></path></svg>
              </a>
                <a className="social-item youTube" href="https://www.youtube.com/@yakovogorodnik">
                  <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="youtube" className="svg-inline--fa fa-youtube " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path>
                  </svg>
                </a>
                <a className="social-item odnoklassniki" href="https://ok.ru/profile/572802197598">
                  <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="odnoklassniki" className="svg-inline--fa fa-odnoklassniki " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="currentColor" d="M275.1 334c-27.4 17.4-65.1 24.3-90 26.9l20.9 20.6 76.3 76.3c27.9 28.6-17.5 73.3-45.7 45.7-19.1-19.4-47.1-47.4-76.3-76.6L84 503.4c-28.2 27.5-73.6-17.6-45.4-45.7 19.4-19.4 47.1-47.4 76.3-76.3l20.6-20.6c-24.6-2.6-62.9-9.1-90.6-26.9-32.6-21-46.9-33.3-34.3-59 7.4-14.6 27.7-26.9 54.6-5.7 0 0 36.3 28.9 94.9 28.9s94.9-28.9 94.9-28.9c26.9-21.1 47.1-8.9 54.6 5.7 12.4 25.7-1.9 38-34.5 59.1zM30.3 129.7C30.3 58 88.6 0 160 0s129.7 58 129.7 129.7c0 71.4-58.3 129.4-129.7 129.4s-129.7-58-129.7-129.4zm66 0c0 35.1 28.6 63.7 63.7 63.7s63.7-28.6 63.7-63.7c0-35.4-28.6-64-63.7-64s-63.7 28.6-63.7 64z"></path>
                  </svg>
                </a>
                <a className="social-item whatsapp" href="/">
                  <svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="whatsapp" className="svg-inline--fa fa-whatsapp " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path>
                  </svg>
                </a>
              </div>
              
            </div>
                    </div>
      
               
            </div>
            <Cart openCart={cartOpen} />
            <div onClick={toggleMenuMode} className="bg-opacity"></div>
        </header>
    );

}

export default HeaderMobile;