import React from "react";
import { Link } from "react-router-dom";
import { ID_LIST, axiosClient } from "../../core/config";
import { Swiper as SwiperClass } from 'swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper/modules';

import SkeletonMainSlider from "../Skeletons/SkeletonMainSlider";



type TSlaider = {
    img_slider: {
        ID: number,
        url: string
    },
    title_slider: string,
    text_slider: string,
    text_btn_slider: string,
    link_btn: string


}


const MainSlider: React.FC = () => {
   
    const [sliderArrow, setSliderArrow] = React.useState<SwiperClass | null>(null)

    const [sliderItems, setSliderItems] = React.useState<TSlaider[]>()



    React.useEffect(() => {
        const fetchSliderItems = async () => {
           
            try {
                const {data} = await axiosClient.get(`wp-json/wp/v2/pages/${ID_LIST.main_page_id}`);
                setSliderItems(data.ACF.slider) 
               
            } catch (err) {
                console.log('ошибка запроса популярных категорий ' + err);
            }
           
        };
        fetchSliderItems() 
    }, [])


    return (
        <section className="main-slider">
            {
                !sliderItems ? <div className="main-slider__item  overflow"><SkeletonMainSlider /></div>
                    : <Swiper
                pagination={{
                    type: 'fraction',
                }}
                modules={[Pagination, Navigation]}
                className="mySwiper"
                onSwiper={(swiper) => setSliderArrow(swiper)}
            >
                {
                    [...sliderItems].reverse().map((obj) => { 
                        return (
                            <SwiperSlide
                            key={obj.img_slider.ID}
                            >
                                <div className="main-slider__item" style={{ backgroundImage: `url('${obj.img_slider.url}')` }}>
                                    <div className="main-slider__info">

                                        <h2 className="main-slider__title">{obj.title_slider}</h2>
                                        <p className="main-slider__text">{obj.text_slider}</p>
                                        <Link to={obj.link_btn} className="main-slider__link">{obj.text_btn_slider}</Link>
                                        <p />
                                    </div> 
                                </div>
                            </SwiperSlide>
                        )
                    })
                }


            </Swiper>
           
            }
             <div className="buttons-swiper">
                <button onClick={() => sliderArrow?.slidePrev()}><FontAwesomeIcon icon={faAngleLeft} /></button>
                <button onClick={() => sliderArrow?.slideNext()}><FontAwesomeIcon icon={faAngleRight} /></button>
            </div>

        </section>
    );
}

export default MainSlider;