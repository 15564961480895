import React from "react";
import { Link } from "react-router-dom";
import { cartShow } from "../../redux/slices/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import { clearItems } from "../../redux/slices/cartSlice";
import CartItem from "../CartItem";
import './cart.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faTrashCan, faXmark } from '@fortawesome/free-solid-svg-icons';
import { Modal } from "antd";
import { RootState } from "../../redux/store";



const Cart: React.FC<{openCart: boolean}> = ({ openCart }) => {
    const dispatch = useDispatch();
    const { items, totalPrice } = useSelector((state: RootState) => state.cart);

    const onClickClear = () => {
        dispatch(clearItems())
    }


    function closeCart() {
        dispatch(cartShow())
    }
    return (
        <Modal className="cart" open={openCart} closeIcon={<FontAwesomeIcon icon={faXmark} />} onCancel={closeCart} footer={null}>
            <div className="cart__top-line"><div className="cart__title">Ваша корзина</div></div>
           
            {
                items.length < 1 ?
                    <div className="cart-empty"> 
                        <div className="icon">
                            <FontAwesomeIcon icon={faCartShopping} />
                        </div>
                        <div className="title">Ваша корзина пуста, перейдите в католог и добавте товар</div>
                        <Link to='/catalog' onClick={closeCart} className="btn-catalog" >Перейти в каталог</Link>
                    </div> :
                    <>
                     <div className="box-scroll">
                        {items.map((item) => {

                            return (

                                <CartItem
                                    key={item.id}
                                    id={item.id}
                                    name={item.name}
                                    price={item.price}
                                    count={item.count}
                                    img={item.img}
                                    
                                />


                            )
                        })}
                        </div>
                        <div className="line-btn-clear">
                            <button onClick={onClickClear} className="btn-clear">
                                <FontAwesomeIcon icon={faTrashCan} />
                                Очистить корзину
                            </button>
                        </div>
                        <div className="card__bottom">
                            <div className="cart__total">
                                <div className="cart__total-text">
                                    Итого:
                                </div>
                                <div className="cart__total-value">
                                    {totalPrice} <span>руб.</span>
                                </div>
                            </div>
                            <div className="box__btn">
                                <Link to='/checkout' className="btn__checkout">
                                    Оформить заказ
                                </Link>
                                <button onClick={closeCart} className="btn__back">
                                    Продолжить покупки
                                </button> 
                            </div>
                        </div>
                    </>

            }






        </Modal>
    );
}

export default Cart;