import React from 'react';
import axios from 'axios';
import { Swiper as SwiperClass } from 'swiper';
import { axiosClient, CONSUMER_KEY, CONSUMER_SECRET } from '../core/config';
import { useDispatch } from "react-redux";
import { addItem, cartShow } from '../redux/slices/cartSlice';
import { useParams } from 'react-router';
import ProductCard from '../components/ProductCard';
import SkeletonFullProduct from '../components/Skeletons/SkeletonFullProduct';
import SkeletonProduct from '../components/Skeletons/SkeletonProduct';
import { useMediaQuery } from '@mui/material';


import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import "swiper/css/pagination";
import 'swiper/css/thumbs';

// import required modules
import { FreeMode, Navigation, Thumbs, Pagination } from 'swiper/modules';



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faAngleLeft, faAngleRight, faCheck, faXmark, faWallet, faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import { ProductItem } from '../redux/slices/productsSlice';


const FullProduct: React.FC = () => {
    const dispatch = useDispatch();
    const styles = {
        '--swiper-navigation-color': '#fff',
        '--swiper-pagination-color': '#fff',
      } as React.CSSProperties;

      const mobileTitle = useMediaQuery('(max-width: 1000px)')
      const showThreeItems = useMediaQuery('(max-width: 1300px)')
  const showTwoItems = useMediaQuery('(max-width: 800px)') 
  const showOneItem = useMediaQuery('(max-width: 550px)')

  
    
    const [similarProducts, setSimilarProducts] = React.useState<ProductItem[] | null>(null);
    const [sliderArrow, setSliderArrow] = React.useState<SwiperClass | null>(null)
    const [sliderArrow2, setSliderArrow2] = React.useState<SwiperClass | null>(null) 
    const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperClass | null>(null);
    const [quantityItem, setQuantityItem] = React.useState(1);

    const plusQuantity = () => {
        setQuantityItem(quantityItem + 1)
    }
    const minusQuantity = () => {
        if(quantityItem > 1) {
            setQuantityItem(quantityItem - 1)
        }
        
    }


    const removeHtmlTags = (htmlString: string) => {
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        return doc.body.textContent || "";
    };
    const [fullProduct, setFullProduct] = React.useState<{
        id: number,
        name: string,
        price: number,
        images: {src: string, id: number}[],
        stock_status: string,
        short_description: string
    } | null>()
    const { id: idFullProduct } = useParams();

  
  

    const source = axios.CancelToken.source();
    React.useEffect(() => {

        async function fetchFullProduct() {
            setFullProduct(null)
            setSimilarProducts(null)
            setThumbsSwiper(null)
            setQuantityItem(1)

            try {
                const { data: currentProduct } = await axiosClient.get(`wp-json/wc/v3/products/${idFullProduct}`,
                    {
                        params: {
                            consumer_key: CONSUMER_KEY,
                            consumer_secret: CONSUMER_SECRET,
                        },
                        cancelToken: source.token,
                    });

                const categories = currentProduct.categories.map((category: {id: number}) => category.id).join(',');

                // Получаем похожие продукты, основываясь на категориях текущего продукта 
                const { data: productsSimilar } = await axiosClient.get(
                    'wp-json/wc/v3/products/',
                    {
                        params: {
                            category: categories,
                            consumer_key: CONSUMER_KEY,
                            consumer_secret: CONSUMER_SECRET,
                            exclude: idFullProduct, // исключаем текущий продукт из результатов
                            per_page: 10, // указываем желаемое количество похожих продуктов

                        },
                        cancelToken: source.token,

                    }

                );
              
                setFullProduct(currentProduct);
                setSimilarProducts(productsSimilar);
               
            } catch (error) {
                if (axios.isCancel(error)) {
                    console.log('Запрос отменен:', error.message);
                } else {
                    console.log('Ошибка в запросе:', error);
                }
            }

        }
        // Прокрутка страницы вверх
        window.scrollTo({ top: 0, behavior: 'smooth' });


        fetchFullProduct();
        return () => {
            // Отменяем запрос при размонтировании компонента
            source.cancel('Запрос отменен при размонтировании компонента');
        };

    }, [idFullProduct]);

    if (!fullProduct) {
        return (
            <div className="product-page">

                <div className="container">
                    <SkeletonFullProduct />
                </div>
            </div>
        )
    }

    const onClickAdd = () => {
        const item = {
            id : fullProduct.id,
            price: fullProduct.price,
            name: fullProduct.name,
            img: fullProduct.images,
            count: quantityItem
        };
        
    dispatch(addItem(item))
    dispatch(cartShow())
    }; 
   
    return (

        <div className="product-page">

            <div className="container">
                <div className="box-flex">
                    <div className="product-photo">
                        {mobileTitle ?  <div className="product-title">
                            {fullProduct.name}
                        </div> : <></>}
                        { 
                        
                            fullProduct.images.length < 2
                                ?
                                <div className="product-photo__full">
                                    <img src={fullProduct.images[0] ? fullProduct.images[0].src : ''} alt="" />
                                </div>
                                :
                                <>
                                    <Swiper
                                        style={styles}
                                        spaceBetween={10}

                                        thumbs={{ swiper: thumbsSwiper }}
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        className="mySwiper2"
                                        onSwiper={(swiper) => setSliderArrow(swiper)}
                                    >
                                        <button className='arrow-swiper arrow-swiper-left' onClick={() => sliderArrow?.slidePrev()}><FontAwesomeIcon icon={faAngleLeft} /></button>
                                        <button className='arrow-swiper arrow-swiper-right' onClick={() => sliderArrow?.slideNext()}><FontAwesomeIcon icon={faAngleRight} /></button>

                                        {fullProduct.images.map((obj) => {

                                            return (
                                                <SwiperSlide className='product-photo__full' key={obj.id}>
                                                    <img src={obj.src} alt="product" />
                                                </SwiperSlide>

                                            )

                                        })}

                                    </Swiper>

                                    <Swiper
                                        onSwiper={setThumbsSwiper}
                                        spaceBetween={10}
                                        slidesPerView={4}
                                        freeMode={true}
                                        watchSlidesProgress={true}
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        className="mySwiper"
                                    >
                                        {fullProduct.images.map((obj) => {

                                            return (
                                                <SwiperSlide className='product-photo__small' key={obj.id}>
                                                    <img src={obj.src} alt="product" />
                                                </SwiperSlide>

                                            )
                                        })}
                                    </Swiper>
                                </>
                        }

                    </div>
                    <div className="product-info">
                        <div className="product-title">
                            {fullProduct.name}
                        </div>
                        {fullProduct.stock_status === 'instock' ?
                            <div className="stock">
                                <span className="icon"><FontAwesomeIcon icon={faCheck} /></span>  В наличии
                            </div>
                            :
                            <div className="stock no-stock">
                                <span className="icon"><FontAwesomeIcon icon={faXmark} /></span>  Нет в наличии
                            </div>}

                        <div className="price">
                            <div className="price-value">
                                {fullProduct.price} <span>руб</span>
                            </div>

                            {fullProduct.stock_status === 'instock' ?
                                <>
                                    <div className="amount__info">
                                        <button onClick={minusQuantity} className="minus">  <FontAwesomeIcon icon={faMinus} /></button>
                                        <div className="amount__value">{quantityItem}</div>
                                        <button onClick={plusQuantity} className="plus"><FontAwesomeIcon icon={faPlus} /></button>
                                    </div>
                                    <button onClick={onClickAdd} className='btn-buy'>Купить</button>
                                </>
                                :
                                <>
                                    <div className="amount__info__not-work amount__info">
                                        <button className="minus">  <FontAwesomeIcon icon={faMinus} /></button>
                                        <div className="amount__value">1</div>
                                        <button className="plus"><FontAwesomeIcon icon={faPlus} /></button>
                                    </div>
                                    <div className='btn-buy not-work'>Купить</div>

                                </>
                            }

                        </div>

                        <div className="info-payment-delivery">
                            <div className="title-line">
                                <span>Доставка и оплата</span>
                                <div className="line"></div>
                            </div>
                            <div className="box-flex">
                                <div className="payment-delivery-item">
                                    <div className="icon"><FontAwesomeIcon icon={faWallet} /></div>
                                    <div className="info">
                                        <div className="title">
                                            Оплата заказа
                                        </div>
                                        <div className="text">
                                            Полная предоплата на карту
                                        </div>
                                    </div>
                                </div>
                                <div className="payment-delivery-item">
                                    <div className="icon"><FontAwesomeIcon icon={faBoxOpen} /></div>
                                    <div className="info">
                                        <div className="title">
                                            Способ доставки
                                        </div>
                                        <div className="text">
                                            Почта России или ...
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="product-description">
                            <div className="title-line">
                                <span>Описание товара</span>
                                <div className="line"></div>
                            </div>
                            <p className='description-text' >
                                {removeHtmlTags(fullProduct.short_description)}
                            </p>
                        </div>


                    </div>

                </div>
                <div className="similar-products">
                    <div className="title-line">
                        <span>similar products</span>
                        <div className="line"></div>
                    </div>

                    <div className="box-flex">
                        <h2 className="section-title">
                            Похожие товара
                        </h2>
                        <div className="buttons-swiper">
                            <button className='arrow-swiper arrow-swiper-left' onClick={() => sliderArrow2?.slidePrev()}><FontAwesomeIcon icon={faAngleLeft} /></button>
                            <button className='arrow-swiper arrow-swiper-right' onClick={() => sliderArrow2?.slideNext()}><FontAwesomeIcon icon={faAngleRight} /></button>
                        </div>
                    </div>

                    <div className="slider-default">


                        <Swiper
                            spaceBetween={50}
                            slidesPerView={showOneItem ? 1  : showTwoItems ? 2 : showThreeItems ? 3 : 4}
                            modules={[Pagination]}
                            pagination={{ clickable: true }}
                            className="mySwiper3"
                            onSwiper={(swiper) => setSliderArrow2(swiper)}
                        >
                            {
                                !similarProducts ? [...new Array(6)].map((_, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div className="slide-loaded">
                                                <SkeletonProduct />
                                            </div>
                                        </SwiperSlide>
                                    )

                                })
                                    : similarProducts.map((obj) => {
                                        return (
                                            <SwiperSlide
                                                key={obj.id}
                                            >
                                                <ProductCard

                                                    key={obj.id}
                                                    id={obj.id}
                                                    stock_status={obj.stock_status}
                                                    images={obj.images}
                                                    name={obj.name}
                                                    short_description={obj.short_description}
                                                    price={Number(obj.price)}
                                                    
                                                />
                                            </SwiperSlide>
                                        )
                                    })


                            }
                        </Swiper>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default FullProduct;