import React from "react"
import ContentLoader from "react-content-loader"

const SkeletonProduct = () => (
   
  <ContentLoader 
  className="product-card"
    speed={2}
    width={307}
    height={412}
    viewBox="0 0 307 412"
    backgroundColor="#dedede"
    foregroundColor="#ecebeb"

  >
   <rect x="0" y="0" rx="20" ry="20" width="307" height="412" />
   
  </ContentLoader>
 
)

export default SkeletonProduct;