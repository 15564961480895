import React from "react";
import { axiosClient, CONSUMER_KEY, CONSUMER_SECRET } from "../../core/config";
import { useMediaQuery } from "@mui/material";
import { Swiper as SwiperClass } from 'swiper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

import ProductCard from "../ProductCard";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import { Pagination } from 'swiper/modules';
import SkeletonProduct from "../Skeletons/SkeletonProduct";
import { ProductItem } from "../../redux/slices/productsSlice";

const PopularProducts: React.FC = () => {
  const showThreeItems = useMediaQuery('(max-width: 1300px)')
  const showTwoItems = useMediaQuery('(max-width: 800px)')
  const showOneItem = useMediaQuery('(max-width: 550px)')

  const [popularProductsItems, setPopularProductsItems] = React.useState<ProductItem[]>();


  React.useEffect(() => {
    const fetchPopularProductsItems = async () => {
      // ?consumer_key=ck_d54247754025c5f412bc9557c7b812ac4fa7e3a9&consumer_secret=cs_d250d8d9fb538642f785e1f89817fc173356c6b4&orderby=popularity&order=desc
      try {
        const { data } = await axiosClient.get('wp-json/wc/v3/products/',
        {
          params: {
            consumer_key: CONSUMER_KEY,
            consumer_secret: CONSUMER_SECRET,
            orderby: 'popularity',
            order: 'desc'

          }
        });
        setPopularProductsItems(data)

      } catch (err) {
        console.log('ошибка запроса популярных категорий ' + err);
      }

    };
    fetchPopularProductsItems()
  }, [])


  const [sliderArrow, setSliderArrow] = React.useState<SwiperClass | null>(null)


  return (
    <section className="main-popular-products">
      <div className="container slider-default">
        <div className="title-line">
          <span>popular products</span>
          <div className="line"></div>
        </div>
        <div className="box-flex">
          <h2 className="section-title">Популярные товары</h2>
          <div className="buttons-swiper">
            <button className='arrow-swiper arrow-swiper-left' onClick={() => sliderArrow?.slidePrev()}><FontAwesomeIcon icon={faAngleLeft} /></button>
            <button className='arrow-swiper arrow-swiper-right' onClick={() => sliderArrow?.slideNext()}><FontAwesomeIcon icon={faAngleRight} /></button>
          </div>
        </div>
        <div className="main-popular-products__slider slider-default">
          <Swiper
            spaceBetween={showThreeItems ? 20 : 50}
            slidesPerView={showOneItem ? 1 : showTwoItems ? 2 : showThreeItems ? 3 : 4}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            onSwiper={(swiper) => setSliderArrow(swiper)}
          >
            {
              !popularProductsItems ? [...new Array(6)].map((_, index) => {
                return (
                  <SwiperSlide
                    key={index}
                  >
                    <div className="slide-loaded">
                      <SkeletonProduct
                        key={index}
                      />
                    </div>

                  </SwiperSlide>

                )
              })
                : popularProductsItems.map((obj) => {

                  return (
                    <SwiperSlide
                      key={obj.id}>

                      <ProductCard
                        id={obj.id}
                        name={obj.name}
                        price={obj.price}
                        short_description={obj.short_description}
                        images={obj.images}
                        stock_status={obj.stock_status}

                      />
                    </SwiperSlide>

                  )
                })
            }
          </Swiper>


        </div>
      </div>
    </section>
  );
}

export default PopularProducts;