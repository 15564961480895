import React from "react";

import { useMediaQuery } from "@mui/material";
import Header from "./Header";
import HeaderMobile from "./HeaderMobile";

const ResponsiveHeader: React.FC<{fixed?: boolean}> = ({fixed}) => {
  
  const isMobile = useMediaQuery('(max-width: 1000px)');

  return isMobile ? <HeaderMobile fixed={fixed} /> : <Header fixed={fixed} />;
};

export default ResponsiveHeader;
