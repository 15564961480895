import React from 'react';
import { axiosClient, CONSUMER_KEY, CONSUMER_SECRET } from '../core/config';
import { useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../redux/store';
import CartItem from '../components/CartItem';
import WindowError from '../components/ModalWindows/WindowError';
import { useNavigate } from "react-router-dom";
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { setInfo } from '../redux/slices/infoClientSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faAngleDown } from '@fortawesome/free-solid-svg-icons';

type TMyformCheckout = {
    name: string;
    lastName: string;
    phone: string; 
    email: string;
    country: string;
    address: string;
    locality : string;
    region: string; 
    postcode: string;
    textMassage: string;

}

const Checkout: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const yourOrderMobile = useMediaQuery('(max-width: 600px)')
    const [openOrder, setOpenOrder] = React.useState(false)
    const {items, totalPrice} = useSelector((state: RootState) => state.cart);
    const [errorSubmit, setErrorSubmit] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState(false); 
    const [isLoading, setIsLoading] = React.useState(false); // Добавили состояние загрузки
    const closeWindow = () => {
        setErrorSubmit(false)
    }
    const toggleOrder = () => {
        setOpenOrder(!openOrder)
    }
    const {register, handleSubmit, formState: {errors}} = useForm<TMyformCheckout>();
    const onSubmit: SubmitHandler<TMyformCheckout> = async (data) => {
        setIsLoading(true); // Устанавливаем состояние загрузки
        try {
            const infoClientData = {
                infoClientOrder: {
                    name: data.name + ' ' + data.lastName,
                    phone: data.phone,
                    email: data.email,
                    country: data.country,
                    address: data.address,
                    locality: data.locality,
                    region: data.region,
                    postcode:  data.postcode,
                }
            };
            const orderData = {
                payment_method: 'cod',
                payment_method_title: 'Cash on Delivery',
                set_paid: false,
                status: 'processing',
                billing: {
                  first_name: data.name,
                  last_name: data.lastName,
                  address_1: 'Адресс: ' + data.address,
                  city: "Город: " + data.locality,
                  state: "Область/район: " + data.region,
                  postcode: "Почтовый индекс: " + data.postcode,
                  country: "Страна: " + data.country,
                  phone: "Номер телефона: " + data.phone,
                  email: data.email
                },
                shipping: {
                  first_name: data.name,
                  last_name: data.lastName,
                  address_1: 'Адресс: ' +  data.address,
                  city: "Город: " + data.locality,
                  state: "Область/район: " + data.region,
                  postcode: "Почтовый индекс: " + data.postcode,
                  country: "Страна: " + data.country
                },
                line_items: items.map(item => ({
                    product_id: item.id,
                    quantity: item.count
                })),
                customer_note: data.textMassage
            };

            const response = await axiosClient.post(
                'wp-json/wc/v3/orders/',
                orderData,
                {
                    params: {
                        consumer_key: CONSUMER_KEY,
                        consumer_secret: CONSUMER_SECRET
                    }
                });
            if (response.data.success || response.status === 201) {
                // Успешное создание заказа
                console.log('Заказ успешно создан!', response.data.orderId);
                dispatch(setInfo(infoClientData));
                setIsSuccess(true);
            } else {
                // Обработка ошибки
                console.error('Ошибка при создании заказа:', response.data);
                setErrorSubmit(true);
            }
        } catch (error) {
            console.error('Ошибка при создании заказа: ', error);
            setErrorSubmit(true);
        } finally {
            setIsLoading(false); // Снимаем состояние загрузки
        }
    }
    const error: SubmitErrorHandler<TMyformCheckout> = (error) => {
        console.log('ошибка при отправлени', error)
    }
    React.useEffect(() => {
        if (items.length === 0) {
            navigate('/');
        }
        if (isSuccess) {
            navigate('/success');
        }
    }, [isSuccess, navigate]);
    if (items.length === 0) {
        navigate('/');
    }
    return ( 
        
        <div className="checkout">
           
            <div className="container"> 
            <WindowError errorSubmit={errorSubmit} closeWindow={closeWindow} />
                <div className="page-title">
                    Оформление заказа
                </div>
                <div className="flex-box">
                    <div className="checkout-left">
                        <form onSubmit={handleSubmit(onSubmit, error)} className='checkout-form'>
                            <div className="info-block">
                                <div className="info-title">Получатель</div>
                                <div className="line"></div>
                            </div>
                            <label className={errors.name ? 'error' : ''}> 
                                <span>Имя</span>
                                <input {...register('name', { 
                                    required: true,
                                    pattern: /^[A-Za-zА-Яа-яЁё\s]+$/i,
                                })} placeholder='Введите ваше имя...' type="text" />
                                {errors.name && errors.name.type === 'required' && (
                                    <p>Поле обязательно для заполнения</p>
                                )}
                                {errors.name && errors.name.type === 'pattern' && (
                                    <p>Имя может содержать только буквы</p>
                                )}
                            </label>
                            <label className={errors.lastName ? 'error' : ''}>
                                <span>Фамилия</span>
                                <input {...register('lastName', {
                                    required: true,
                                    pattern: /^[A-Za-zА-Яа-яЁё\s]+$/i,
                                })} placeholder='Введите вашу фамилию...' type="text" />
                                {errors.lastName && errors.lastName.type === 'required' && (
                                    <p>Поле обязательно для заполнения</p>
                                )}
                                {errors.lastName && errors.lastName.type === 'pattern' && (
                                    <p>Фамилия может содержать только буквы</p> 
                                )}
                            </label>
                            <label className={errors.phone ? 'error' : ''}>
                                <span>Телефон</span>
                                <input {...register('phone', {
                                    required: true,
                                    pattern: /^[+]?[0-9]+$/,
                                    minLength: 11,
                                })} placeholder='Введите ваш телефон...' type="text" />
                                {errors.phone && errors.phone.type === 'required' && (
                                    <p>Поле обязательно для заполнения</p>
                                )}
                                {errors.phone && errors.phone.type === 'pattern' && (
                                    <p>Телефон может содержать только цифры</p> 
                                )}
                                {errors.phone && errors.phone.type === 'minLength' && (
                                    <p>Телефона должен содержать минимум 11 символов</p>
                                )}
                            </label>
                            <label className={errors.email ? 'error' : ''}>
                                <span>E-mail</span>
                                <input {...register('email', {
                                    required: true,
                                    pattern:  /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                })} placeholder='Введите ваш E-mail...' type="text" />
                                {errors.email && errors.email.type === 'required' && (
                                    <p>Поле обязательно для заполнения</p>
                                )}
                                {errors.email && errors.email.type === 'pattern' && (
                                    <p>Введите корректный email</p> 
                                )}
                            </label>
                            <div className="info-block">
                                <div className="info-title">Доставка</div>
                                <div className="line"></div>
                            </div>
                            <label>
                                <span>Страна</span>
                                <input {...register('country')} type="text" value='Россия' />
                            </label>
                            <label className={errors.address ? 'error' : ''}>
                                <span>Адрес</span>
                                <input
                                {...register('address', {
                                    required: true,
                                })}
                                placeholder='Введите ваш адрес...' type="text" />
                                {errors.address && errors.address.type === 'required' && (
                                    <p>Поле обязательно для заполнения</p>
                                )}
                            </label>
                            <label className={errors.locality ? 'error' : ''}>
                                <span>Населенный пункт</span>
                                <input {...register('locality', {
                                    required: true,
                                })} placeholder='Введите ваш населенный пункт...' type="text" />
                                 {errors.locality && errors.locality.type === 'required' && (
                                    <p>Поле обязательно для заполнения</p>
                                )}
                            </label>
                            <label className={errors.region ? 'error' : ''}>
                                <span>Область/район</span>
                                <input {...register('region', {
                                    required: true,
                                })} placeholder='Введите вашу область или район...' type="text" />
                                 {errors.region && errors.region.type === 'required' && (
                                    <p>Поле обязательно для заполнения</p>
                                )}
                            </label>
                            <label className={errors.postcode ? 'error' : ''}>
                                <span>Почтовый индекс</span>
                                <input {...register('postcode', {
                                    required: true,
                                    pattern: /^\d{5,6}(-\d{4})?$/,  
                                })} placeholder='Введите почтовый индекс...' type="text" />
                                 {errors.postcode && errors.postcode.type === 'required' && (
                                    <p>Поле обязательно для заполнения</p>
                                )}
                                {errors.postcode && errors.postcode.type === 'pattern' && (
                                    <p>Введите корректный почтовый индекс</p> 
                                )}
                            </label>

                            <div className="info-block">
                                <div className="info-title">Коментарий к заказу</div> 
                                <div className="line"></div>
                            </div>

                            <textarea {...register('textMassage')} placeholder='Примечание к вашему заказу, например, особые пожелания отделу доставки...'></textarea>

                            <button 
                                className='btn-checkout' 
                                disabled={isLoading}
                            >
                                {isLoading ? 'Обработка...' : 'Оформить заказ'}
                            </button>
                        </form>
                    </div>
                    <div  className={`checkout-order ${yourOrderMobile ? 'checkout-order-mobile' : ''}`}>
                        <div onClick={toggleOrder} className={`info-block ${openOrder ? 'open' : ''}`}>
                            {yourOrderMobile ? <div className='icon-cart'><FontAwesomeIcon icon={faCartShopping} /></div> : <></>}
                            <div className="info-title">Ваш заказ</div>
                            <div className="line"></div>
                            {yourOrderMobile ? <div className='icon-arrow'><FontAwesomeIcon icon={faAngleDown} /></div> : <></>}
                        </div>
                        <div className={`checkout-order__info ${openOrder ? 'open' : ''}`}>
                        <div className="order-products">
                            {items.map((obj) => {
                                return (
                                    <CartItem 
                                    key={obj.id}
                                    id={obj.id}
                                    count={obj.count}
                                    price={obj.price}
                                    img={obj.img}
                                    name={obj.name}
                                    />
                                )
                            })}
                        </div>
                        <div className="cart__total">
                            <div className="cart__total-text">
                                Итого:
                            </div>
                            <div className="cart__total-value">
                                {totalPrice} <span>руб.</span>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Checkout;
