import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getCartFromLocalStorage } from "../../utils/getCartFromLocalStorage";
import { calcTotalPrice } from "../../utils/calcTotalPrice";

export type TCartItem = {
    id: number,
      name: string,
      price: number,
      img: {src: string}[], 
      count: number
}


interface CartSliceState {
    cartOpen: boolean,
    totalPrice: number, 
    items: TCartItem[]

}
const {items, totalPrice} = getCartFromLocalStorage()
const initialState: CartSliceState = {
    cartOpen: false,
    totalPrice,
    items
} 

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        cartShow(state) {
            state.cartOpen = !state.cartOpen
        },
        addItem(state, action: PayloadAction<TCartItem>) {

             const findItem = state.items.find(obj => obj.id === action.payload.id);
             if (findItem) {
               
                findItem.count = action.payload.count
                
                
             } else {
                state.items.push({
                    ...action.payload,
                   
                    

                })
             }
             state.totalPrice = calcTotalPrice(state.items)
        },
        plusItem(state, action: PayloadAction<number>) {
            const findItem = state.items.find(obj => obj.id === action.payload);
            if(findItem) {
                findItem.count++;
            }
            state.totalPrice = state.items.reduce((sum, obj) => {
                return Number(obj.price * obj.count) + sum
             }, 0)
        },
        minusItem(state, action: PayloadAction<number>) {
            const findItem = state.items.find(obj => obj.id === action.payload);
            if(findItem) {
                findItem.count--;
            }
            state.totalPrice = state.items.reduce((sum, obj) => {
                return Number(obj.price * obj.count) + sum
             }, 0)
        },
        removeItem(state, action: PayloadAction<number>) {
            state.items = state.items.filter(obj => obj.id !== action.payload)
            state.totalPrice = state.items.reduce((sum, obj) => {
                return Number(obj.price * obj.count) + sum
             }, 0)
        },
        clearItems(state) {
           state.items = [];
           state.totalPrice = 0;
           
        }
        
    }
})

export const {addItem, removeItem, clearItems, plusItem, minusItem, cartShow} = cartSlice.actions;

export default cartSlice.reducer