import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash'

interface CategorySliceState {
    selectedCategories: Category[]
}

export interface Category {
    id: number;
    name: string;
    parent?: number;
    children?: Category[]
}

// Разворачивает список категорий, чтобы убрать вложенный children и в store они хранились подряд
/**
 * Вместо: 
 * [
 *  {id: 1, name: 'test', children: [{id: 2, name: 'kek'}]}
 * ]
 * 
 * Делает:
 * [
 * {id: 1, name: 'test'},
 * {id: 2, name: 'kek'},
 * ]
 *  
 */
const getFlatMembers = (mem: Category): any => {
    const member = { ...mem }; // copy
    delete member?.children;

    if (!mem?.children || !mem?.children.length) {
        return member; // return copied
    }

    // return copied, but pass original to flatMapDeep
    return [
        member,
        _.flatMapDeep(mem?.children, getFlatMembers)
    ];
}

/**
 * У категорий со вложенными элементами есть поле children, в котором хранится  массив подкатегорий
 * Когда мы отмечаем категорию, у которой есть подкатегории, нужно получить еще и список вложенных категорий
 * Эта функция так и делает, а шо
 * @param category 
 * @returns 
 */
function getSelectedSubCategories(category: Category) {
    let itemsFlat: Category[] = _.flatMapDeep(category.children, getFlatMembers)
    let { children, ...restCategory } = category;
    const categoryCopy: Category = { ...restCategory };

    return [categoryCopy, ...itemsFlat];
}

// Удаляет из массива "b" элементы, которые есть в массиве "а"
function diff(a: Category[], b: Category[]) {
    const set = new Set(a.map(({ id }) => id));
    return b.filter(({ id }) => !set.has(id));
}


const initialState: CategorySliceState = {
    selectedCategories: [],
}

const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        // Установка массива выбранных категорий
        setSelectedCategories: (state, action: PayloadAction<Category[]>) => {
            state.selectedCategories = action.payload;
        },
        
        // добавление отмеченной категории и подкатегорий (если они есть)
        addSelectedCategory: (state, action: PayloadAction<Category>) => {
            const selectedCategory = action.payload;
            let selectedList = getSelectedSubCategories(selectedCategory); // получаем список всех выделенных подкатегорий + выбранная категория

            let temp = state.selectedCategories.filter(category => {
                return !!selectedList.find(c => c.id !== category.id)
            });

            temp = temp.concat(selectedList)
            state.selectedCategories = temp;
        },

        // удаление отмченной категории и подкатегорий, если они есть
        removeSelectedCategory: (state, action: PayloadAction<Category>) => {
            let selectedList = getSelectedSubCategories(action.payload); // получаем список всех выделенных подкатегорий + выбранная категория
            state.selectedCategories = diff(selectedList, state.selectedCategories);
        },
    }
})

export const { addSelectedCategory, removeSelectedCategory, setSelectedCategories } = categorySlice.actions;

export default categorySlice.reducer