import axios from "axios";
import { axiosClient, CONSUMER_KEY, CONSUMER_SECRET } from "../../core/config";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import { filterActive } from "./filterSlice";


type FetchProductsArgs = {
    filterActive: filterActive,
    search?: string,
    searchInfo?: string,
    currentPage: number,
    categoryId: string,
}

export type ProductItem = {
    id: number,
    name: string,
    price: number,
    images: { src: string; id: number }[],
    count?: number,
    short_description: string,
    stock_status: string
}



export const fetchProducts = createAsyncThunk<{ products: ProductItem[], totalPage: number }, FetchProductsArgs>(
    'products/fetchProductsStatus',
    async (params) => {
        const { filterActive, search, currentPage, categoryId } = params;

        // Получаем первые 9 товаров, которые есть в наличии
        const inStockResponse = await axios.get<ProductItem[]>(`https://dev.yakovogorodnik.ru/wp-json/wc/v3/products/?consumer_key=${CONSUMER_KEY}&consumer_secret=${CONSUMER_SECRET}&category=${categoryId}&per_page=9&page=${currentPage}&orderby=${filterActive.sortProperty}&search=${search}&stock_status=instock`);
        const inStockProducts = inStockResponse.data;

        // Получаем товары, которых нет в наличии
        const outOfStockResponse = await axios.get<ProductItem[]>(`https://dev.yakovogorodnik.ru/wp-json/wc/v3/products/?consumer_key=${CONSUMER_KEY}&consumer_secret=${CONSUMER_SECRET}&category=${categoryId}&per_page=9&page=${currentPage}&orderby=${filterActive.sortProperty}&search=${search}&stock_status=outofstock`);
        const outOfStockProducts = outOfStockResponse.data;

        // Сначала добавляем товары в наличии, затем - те, которых нет
        const combinedProducts = [...inStockProducts, ...outOfStockProducts].slice(0, 9);

        // Вычисляем общее количество товаров
        const totalProducts = Number(inStockResponse.headers['x-wp-total']) + Number(outOfStockResponse.headers['x-wp-total']);

        // Вычисляем общее количество страниц
        const totalPage = Math.ceil(totalProducts / 9);

        return { products: combinedProducts, totalPage };
    }
) 




interface ProductSliceState { 
    totalPage: number,
    items: ProductItem[],
    status: 'loading' | 'success' | 'error'
}

const initialState: ProductSliceState = {
    totalPage: 1,
    items: [],
    status: 'loading', // loading | success | error
}

const productsSlice = createSlice({
    name: 'products',
    initialState,
    reducers: {
        setItems(state, action) {
            state.items = action.payload;
        }

    },






    extraReducers: (builder) => {
        builder
            .addCase(fetchProducts.pending, (state) => {
                state.status = "loading"
                state.items = [];
                state.totalPage = 1;
            })
            .addCase(fetchProducts.fulfilled, (state, action) => {
                state.items = action.payload.products;
                state.totalPage = action.payload.totalPage;
                
                state.status = "success";
               

            })
            .addCase(fetchProducts.rejected, (state) => {
                // Обработчик для реджекта
                state.status = 'error';
                state.items = [];
                console.log('ошибка в productSlice')
                
            });

    }
})

export const { setItems } = productsSlice.actions;

export default productsSlice.reducer