import React from "react";
import { TCartItem } from "../../redux/slices/cartSlice";
import { useMediaQuery } from '@mui/material';

const SuccessItem: React.FC<TCartItem> = ({id, count, price, name, img }) => {

    const cartItemMobile = useMediaQuery('(max-width: 600px)')
    return (
        cartItemMobile ? 
        <div className="cart__product-card mobile">
        <img className="img__product" src={img[0] ? img[0].src : ''} alt="" />
        <div className="box-title-price">
                <div className="product__title">
                        {name}
                </div>
                <div className="price">
                        <div className="title__column">
                            ЦЕНА
                        </div>

                        <div className="price__value">
                            {price} <span>руб.</span>
                        </div>


                </div>
            </div>
        <div className="info__product">
          
            <div className="info__bottom">
               
                <div className="amount">
                    <div className="title__column">
                        КОЛИЧЕСТВО
                    </div>
                    <div className="amount__info">
                        
                        <div className='amount__value'>{count}</div>

                    </div>
                </div>

                <div className="total">
                    <div className="title__column">ИТОГО</div>
                    <div className="total__value">
                        {price * count} <span>руб.</span>
                    </div>
                </div>
            </div>


        </div>
    </div> 
    :
    <div className="cart__product-card">
    <img className="img__product" src={img[0] ? img[0].src : ''} alt="" />
    <div className="info__product">
        <div className="info__top">
            <div className="product__title">
                {name}
            </div>
           
        </div>
        <div className="info__bottom">
            <div className="price">
                <div className="title__column">
                    ЦЕНА
                </div>

                <div className="price__value">
                    {price} <span>руб.</span>
                </div>


            </div>
            <div className="amount">
                <div className="title__column">
                    КОЛИЧЕСТВО
                </div>
                <div className="amount__info">
                    
                    <div className='amount__value'>{count}</div>

                </div>
            </div>

            <div className="total">
                <div className="title__column">ИТОГО</div>
                <div className="total__value">
                    {price * count} <span>руб.</span>
                </div>
            </div>
        </div>


    </div>
</div>
    )
}

export default SuccessItem;