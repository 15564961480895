import React from 'react';

const PaymentDelivery: React.FC = () => {
    return (
        <div className="payment-delivery">
            <div className="container">
                <div className="page-title">
                    Оплата и доставка
                </div>
                <div className="payment-delivery__info">
                    <div className="payment-delivery__info-text">
                        <span>Оплата</span>
                        <p> Работаем по полной предоплате — заказ плюс пересыл. Стоимость пересыла зависит от веса посылки, дальности отправки, выбранного тарифа отправки. После подтверждения заказа мы с вами свяжемся по электронной почте, посчитаем сумму заказа с пересылом и сообщим реквизиты для перевода а так же ответим на все интересующие вас вопросы.(Если сообщение на почту не пришло проверьте вкладку СПАМ)</p>
                        <span>Доставка</span>
                        <p>Почтовые отправки товаров только по территории РФ. Отправляем почтой России — первым классом, EMS, обычной посылкой. После отправки присылаем на указанный электронный адрес фото чека и номер отслеживания посылки. Срок доставки заказа почтой в среднем 3-10 дней, зависит от дальности отправки.</p>
                    </div>
                    <img className='payment-delivery__info-img' src="https://clevercare.ru/upload/medialibrary/5f0/a04zadpty8ft8wtivogd5jtg38k8is62.png" alt="" />
                </div>
            </div>

            <div className="packing-order">
                <div className="container">
                    <div className="title-line">
                        <span>packing order</span>
                        <div className="line"></div>
                    </div>

                    <div className="box-flex">

                        <div className="packing-order__video">
                            <iframe width="560" height="315" src="https://www.youtube.com/embed/K2Se18d6ZiI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </div>

                        <div className="packing-order__info">
                            <h2 className="section-title">
                                Как происходит упаковка заказа
                            </h2>

                            <div className="packing-order__info-text">
                                Отправляем укорененные черенки в 100-350мл таре. Качественно упаковываем каждое растение, чтобы не было повреждений в пути. Растение от пересыла, в темной коробке — в любом случае получит стресс, важно правильно его распаковать и дать нужные условия. Настоятельно рекомендую посмотреть видео слева, где все ответы на вопросы как упаковываем, какие растения в каком виде отправляем, в каком виде вы их получите и подробная инструкция что делать с вашими новыми черенками после посылки.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="warning">
                    <div className="title-line">
                        <span>warning</span>
                        <div className="line"></div>
                    </div>
                    <h2 className="section-title">
                        Важно!
                    </h2>
                    <p>Предзаказы и бронь не делаем! С растениями многое не предсказуемо. По этому — продажа только по факту наличия. Отправка заказа происходит в течении 7ми дней после оплаты. Отправляем растения практически круглый год, исключение — погодные условия, мороз ниже -5.</p>
                </div>
            </div>
        </div>
    );
}


export default PaymentDelivery;