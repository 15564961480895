import React from 'react';


const FooterCheckout:React.FC = () =>  {
    return(
        <footer className="footer footer-checkout">

    <div className="footer-bottom">
        <div className="container">

        </div>
    </div>


</footer>

    )
}

export default FooterCheckout;