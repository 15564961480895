import React from "react";
import {
  Routes, 
  Route
} from 'react-router-dom';
import ScrollToTopOnPageChange from "./components/ScrollToTopOnPageChange";

import Home from "./pages/Home";
import Catalog from "./pages/Catalog";
import FullProduct from "./pages/FullProduct";
import NotFound from "./pages/NotFound";
import HomeLayouts from './components/layouts/HomeLayouts';
import PageLayouts from './components/layouts/PageLayouts';
import CheckoutLayouts from "./components/layouts/CheckoutLayouts";
import Reviews from "./pages/Reviews";
import Contacts from "./pages/Contacts";
import PaymentDelivery from "./pages/PaymentDelivery";
import Checkout from "./pages/Checkout";
import Success from "./pages/Success";
import LegalInformation from "./pages/LegalInformation";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Education from "./pages/Education";
import EducationChrysanthemum from "./pages/Education-chrysanthemum";
import EducationCucumber from "./pages/Education-cucumber";
import EducationPepper from "./pages/Education-pepper";
import EducationTomato from "./pages/Education-tomato";
import EducationBaclan from "./pages/Education-baclan";

 


import './scss/app.scss';
import { useSelector } from "react-redux";
import { RootState } from "./redux/store";





function App() {
  const {menuOpen} = useSelector((state: RootState) => state.mobileMenu)
  
  return (
    <div className={`wrapper ${menuOpen ? 'mobile-menu_show' : ''}`} >  
       <ScrollToTopOnPageChange />

        <div id="content">
          <Routes>
            <Route path="/" element={<HomeLayouts><Home /></HomeLayouts>} />
            <Route path="/catalog" element={<PageLayouts><Catalog /></PageLayouts>} />
            <Route path="/product/:id" element={<PageLayouts><FullProduct /></PageLayouts>} />
            <Route path="/reviews" element={<PageLayouts><Reviews /></PageLayouts>} /> 
            <Route path="/education" element={<PageLayouts><Education /></PageLayouts>} /> 
            <Route path="/education/education-chrysanthemum" element={<HomeLayouts><EducationChrysanthemum /></HomeLayouts>} /> 
            <Route path="/education/education-baclan" element={<HomeLayouts><EducationBaclan /></HomeLayouts>} /> 
            <Route path="/education/education-cucumber" element={<HomeLayouts><EducationCucumber /></HomeLayouts>} /> 
            <Route path="/education/education-tomato" element={<HomeLayouts><EducationTomato /></HomeLayouts>} /> 
            <Route path="/education/education-pepper" element={<HomeLayouts><EducationPepper /></HomeLayouts>} /> 
            <Route path="/contacts" element={<PageLayouts><Contacts /></PageLayouts>} /> 
            <Route path="/payment-delivery" element={<PageLayouts><PaymentDelivery /></PageLayouts>} /> 
            <Route path="/privacy-policy" element={<PageLayouts><PrivacyPolicy /></PageLayouts>} /> 
            <Route path="/legal-information" element={<PageLayouts><LegalInformation /></PageLayouts>} /> 
            <Route path="/checkout" element={<CheckoutLayouts><Checkout /></CheckoutLayouts>} /> 
            <Route path="/success" element={<CheckoutLayouts><Success /></CheckoutLayouts>} /> 
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
        

    </div>




  );
}

export default App;
