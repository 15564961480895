import React from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../redux/store';
import { Link, useNavigate } from 'react-router-dom';
import SuccessItem from '../components/SuccessItem';
import { clearItems } from '../redux/slices/cartSlice';
import {  clearInfo } from '../redux/slices/infoClientSlice';
import { useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartShopping, faAngleDown } from '@fortawesome/free-solid-svg-icons';


const Success: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const yourOrderMobile = useMediaQuery('(max-width: 600px)')
    const [openOrder, setOpenOrder] = React.useState(false)
    const toggleOrder = () => {
        setOpenOrder(!openOrder)
    }
    const {items, totalPrice} = useSelector((state: RootState) => state.cart);
    const {infoClientOrder} = useSelector((state: RootState) => state.infoClient);
    
    React.useEffect(() => {
        if (infoClientOrder.phone === '') {
            navigate('/');
            console.log('пусто')
        }
        return () => {
            dispatch(clearItems());
            dispatch(clearInfo());
            localStorage.clear();
        };
    }, [dispatch]);
    
    
console.log(infoClientOrder)
    return (
        <div className="success-page">
            <div className="container">
                <div className="page-title">
                    Спасибо за ваш заказ
                </div>
                <div className="flex-box">
                    <div className="client-info">
                        <div className="info-block">
                            <div className="info-title">Информация о заказе</div>
                            <div className="line"></div>
                        </div>
                        <div className="item-info">
                            <div className="title-info">Страна</div>
                            <div className="value-info">Россия</div>
                        </div>
                        <div className="item-info">
                            <div className="title-info">Населенный пункт</div>
                            <div className="value-info">{infoClientOrder.locality}</div>
                        </div>
                        <div className="item-info">
                            <div className="title-info">Адресс</div>
                            <div className="value-info">{infoClientOrder.address}</div>
                        </div>
                        <div className="item-info">
                            <div className="title-info">Почтовый индекс</div>
                            <div className="value-info">{infoClientOrder.postcode}</div>
                        </div>
                        <div className="item-info">
                            <div className="title-info">Оплата</div>
                            <div className="value-info">Полная предоплата</div>
                        </div>
                        <div className="item-info">
                            <div className="title-info">Доставка</div>
                            <div className="value-info">Почта России</div>
                        </div>
                        <div className="item-info">
                            <div className="title-info">Сумма заказа</div>
                            <div className="value-info">{totalPrice} руб</div>
                        </div>
                        <div className="info-block">
                            <div className="info-title">Информация о клиенте</div>
                            <div className="line"></div>
                        </div>
                        <div className="item-info">
                            <div className="title-info">Имя</div>
                            <div className="value-info">{infoClientOrder.name}</div>
                        </div>
                        <div className="item-info">
                            <div className="title-info">E-mail</div>
                            <div className="value-info">{infoClientOrder.email}</div>
                        </div>
                        <div className="item-info">
                            <div className="title-info">Телефон</div>
                            <div className="value-info">{infoClientOrder.phone}</div>
                        </div>
                       
                        <Link to='/' className='btn-back'>Перейти на главную страницу</Link>
                    </div>

                    <div className={`order-info  ${yourOrderMobile ? 'checkout-order-mobile' : ''}`}>
                    <div onClick={toggleOrder} className={`info-block ${!openOrder ? 'open' : ''}`}>
                            {yourOrderMobile ? <div className='icon-cart'><FontAwesomeIcon icon={faCartShopping} /></div> : <></>}
                            <div className="info-title">Ваш заказ</div>
                            <div className="line"></div>
                            {yourOrderMobile ? <div className='icon-arrow'><FontAwesomeIcon icon={faAngleDown} /></div> : <></>}
                        </div>
                        <div className={`checkout-order__info ${!openOrder ? 'open' : ''}`}>
                        <div className="order-products">
                            {items.map((item) => {
                                return(
                                    <SuccessItem
                                        key={item.id}
                                        id={item.id}
                                        name={item.name}
                                        count={item.count}
                                        price={item.price}
                                        img={item.img}                                     /> 
                                )
                            })}
                          
                        </div>
                        <div className="cart__total">
                            <div className="cart__total-text">
                                Итого:
                            </div>
                            <div className="cart__total-value">
                                {totalPrice} <span>руб.</span>
                            </div>
                        </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    );
}

export default Success;